import styled from "styled-components";
import { Body } from "../shared-components";

export default function StatusPage() {
  return (
    <Body>
      <Styles>
        <h1>Sorry, we're down at the moment.</h1>
        <p>
          Oops! Due to higher than anticipated demand, we have blown through our
          ChatGPT Quota.
        </p>
        <p>
          We hope to have the quota raised and get back online as soon as
          possible.
        </p>
        <p>Thank you for your patience! We hope to be back online soon.</p>
        <img alt="down" className="framed" src="/images/not-found.jpg" />
      </Styles>
    </Body>
  );
}

const Styles = styled.div`
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;

  img {
    width: clamp(20em, 50vw, 50em);
    height: auto;
  }
`;
