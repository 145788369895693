import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import { Actions, isMobileScreen } from "../shared-components";

export default function CharacterForm({ gameSystem, createCharacter }) {
  const [level, setLevel] = useState(1);
  const [prompt, setPrompt] = useState("");

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setPrompt(getExamplePrompt(gameSystem));
  }, [gameSystem]);

  function refeshPrompt() {
    setPrompt(getExamplePrompt(gameSystem));
  }
  const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  function onSubmit(e) {
    e.preventDefault();
    createCharacter(level, prompt);
  }

  return (
    <Styles>
      <form className="character-form" onSubmit={onSubmit}>
        <div className="character-prompt">
          <select value={level} onChange={(e) => setLevel(e.target.value)}>
            {levels.map((l) => (
              <option key={l} value={l}>
                Level {l}
              </option>
            ))}
          </select>
          {isMobileScreen() && (
            <textarea
              ref={inputRef}
              name="prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
          )}
          {!isMobileScreen() && (
            <>
              <input
                ref={inputRef}
                type="text"
                name="prompt"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <RefreshButton refeshPrompt={refeshPrompt} />
            </>
          )}
        </div>
        <Actions style={{ marginTop: "1em", marginRight: 0 }}>
          {isMobileScreen() && <RefreshButton refeshPrompt={refeshPrompt} />}

          <button type="submit" onClick={onSubmit}>
            Roll Me Up a Character!
          </button>
        </Actions>
        <div className="help">
          <p>
            Say as much or as little as you like, and I'll fill in the rest.
            I'll come up for a name for you, attributes, backstory, magic items,
            spells etc. Or you can specify some or all of them yourself.
          </p>
          <p>
            Your character will be the protagonist in their own campaign with
            many adventures. You can invite your friends to join the campaign,
            and NPCs will also join along the way.
          </p>
        </div>
      </form>
    </Styles>
  );
}

function RefreshButton({ refeshPrompt }) {
  return (
    <button type="button" className="regen-prompt" onClick={refeshPrompt}>
      <FontAwesomeIcon icon={faSync} />
    </button>
  );
}

const Styles = styled.div`
  .character-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    h3 {
      margin-top: 1em;
      margin-bottom: 0.1em;
    }

    .character-prompt {
      display: flex;
      align-items: center;
      // only the text area select
      textarea {
        width: 100%;
        font-size: 18px;
        height: 4em;
        padding: 0.5em;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
      select {
        padding: 6px 0;
        font-size: 18px;
        margin-right: 10px;
        @media (max-width: 600px) {
          display: none;
        }
      }
      input {
        flex-grow: 1;
        padding: 6px;
        /* width: 25em; */
        font-size: 18px;
      }

      button.regen-prompt {
        color: white;
        padding: 7px;
        img {
          padding-left: 2px;
          padding-top: 2px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .help {
      margin-top: 1em;
      font-style: italic;
    }
  }
`;

function getExamplePrompt(gameSystem) {
  let examples;
  if (gameSystem.exampleCharacterPrompts) {
    examples = gameSystem.exampleCharacterPrompts;
  } else {
    examples = [];
  }

  const random = Math.floor(Math.random() * examples.length);
  return examples[random] || "";
}
