import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { isTabletScreen } from "../shared-components";
import AdventurePanel from "./AdventurePanel";
import Adventure from "./Adventure";
import { AdventureStoryIntro } from "./AdventureDialog";
import NotFound from "./NotFound";
import { toast } from "react-toastify";

export default function Character() {
  const [character, setCharacter] = useState(null);
  const [adventure, setAdventure] = useState(null);
  const [showIntro, setShowIntro] = useState(false);
  const [introDismissed, setIntroDismissed] = useState(false);
  const [pcStates, setPcStates] = useState({});
  const [status, setStatus] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const { apiClient, userId, unauthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (!id || !apiClient) return;
    // check url params for adventure id
    const urlParams = new URLSearchParams(window.location.search);
    const adventureId = urlParams.get("adventureId");
    let adventureUrl = `/characters/${id}/adventure`;
    if (adventureId) {
      adventureUrl = `/adventures/${adventureId}`;
    }

    apiClient
      .get(adventureUrl)
      .then(({ data }) => {
        setAdventure(data);
      })
      .catch(() => {
        setNotFound(true);
      })
      .then(() => {
        apiClient.get(`/characters/${id}`).then(({ data }) => {
          setCharacter(data);
        });
      });
  }, [id, apiClient, status]);

  useEffect(() => {
    if (!adventure) return;
    const isNewAdventure = adventure?.transcript?.messages?.length === 0;
    setShowIntro(isNewAdventure);
  }, [adventure]);

  // poll the campaign every second for status updates
  useEffect(() => {
    if (!adventure) return;
    const interval = setInterval(() => {
      apiClient
        .get(`/campaigns/${adventure.campaignId}/status`)
        .then(({ data }) => {
          setStatus(data.status);

          // do a deep equality check to avoid unnecessary re-renders
          if (JSON.stringify(data.pcStates) === JSON.stringify(pcStates))
            return;
          setPcStates(data.pcStates);
        });
    }, 1000);
    return () => clearInterval(interval);
  }, [adventure, apiClient, pcStates]);

  function dismissIntro() {
    setShowIntro(false);
    setIntroDismissed(true);
    console.log("Dismiss intro", character.playerId, userId);
    if (character.playerId === userId) {
      toast(`What do you do, ${character.name}?`, {
        autoClose: 3000,
        hideProgressBar: true,
        position: "bottom-right",
      });
    } else if (unauthenticated) {
      console.log(character.playerID, userId);
      toast(`Sign up or login to join the adventure!`, {
        autoClose: 3000,
        hideProgressBar: true,
        position: "bottom-right",
      });
    }
  }

  if (notFound) return <NotFound />;
  if (!character || !adventure) return "";

  // if not done creating the character, redirect to the create character page
  if (
    !adventure ||
    (status === "Creating Adventure" && character.isLeadCharacter)
  ) {
    // redirect
    navigate(`/characters/${character.id}/create-campaign`);
  }

  document.title = `${character.name} (Rolecraft)`;

  return (
    <Styles>
      {showIntro && !introDismissed && (
        <AdventureStoryIntro adventure={adventure} onClose={dismissIntro} />
      )}
      {!isTabletScreen() && (
        <AdventurePanel
          adventure={adventure}
          character={character}
          pcStates={pcStates}
        />
      )}
      <Adventure
        character={character}
        adventureId={adventure.id}
        status={status}
        pcStates={pcStates}
      />
    </Styles>
  );
}

const Styles = styled.div`
  display: flex;
  flex-direction: row;
`;
