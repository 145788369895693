import { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { AuthContext, supabase } from "../AuthContext";
import { Auth } from "@supabase/auth-ui-react";
import Colors, {
  Actions,
  Footer,
  Header,
  Tooltip,
  isTabletScreen,
} from "../shared-components";
import ThinkingDice from "./ThinkingDice";
import {
  faClipboard,
  faGear,
  faShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Transcript from "./Transcript";
import AdventurePanel from "./AdventurePanel";
import Image from "./Image";
import CampaignDialog from "./CampaignDialog";
import CenteredModal from "./CenteredModal";
import { characterUrl } from "./Main";
import { useNavigate } from "react-router-dom";

export default function Adventure({
  character,
  adventureId,
  status,
  pcStates,
}) {
  const { apiClient, userId, unauthenticated } = useContext(AuthContext);
  const [adventure, setAdventure] = useState(null);
  const [dmThinking, setDmThinking] = useState(false);
  const [showAdventurePanel, setShowAdventurePanel] = useState(false);
  const [showCampaignDialog, setShowCampaignDialog] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);

  const pollPerod = 1000;

  // players can view other players' characters and adventures
  // and can join them if the owner allows it
  const isPlayerCharacter = character.playerId === userId;

  // determine the screen size and set the height of the transcript
  // auto-scroll to the bottom of the transcript (desktop only)
  useEffect(() => {
    apiClient.get(`/adventures/${adventureId}`).then(({ data }) => {
      setAdventure(data);
    });
  }, [apiClient, adventureId, dmThinking]);

  // poll for changes to the adventure when not processing a user prompt
  useEffect(() => {
    if (!apiClient || !adventure) return;
    const interval = setInterval(() => {
      apiClient
        .get(`/adventures/${adventure.id}/since/${adventure.updatedAt}`)
        .then(({ data }) => {
          setAdventure(data);
          setDmThinking(false);
        })
        .catch((error) => {
          if (error.response?.status === 304) {
            return;
          } else {
            console.log("Error checking for adventure updates", error);
          }
        });
    }, pollPerod);
    return () => clearInterval(interval);
  }, [adventure, apiClient, dmThinking, pollPerod]);

  if (!adventure) return "";

  function submitUserPrompt(userPrompt) {
    if (userPrompt.length < 3) return;
    const body = { message: userPrompt };
    setDmThinking(true);
    apiClient
      .post(`/characters/${character.id}/say`, body)
      .then(({ data }) => {
        setDmThinking(false);
        setAdventure(data);
      })
      .catch((error) => {
        console.log("Error submitting user prompt", error);
      })
      .finally(() => {
        setDmThinking(false);
      });
  }

  const headerClass = isTabletScreen() ? "clickable" : "";
  const footerClass = isCombat(adventure) ? "combat" : "";

  return (
    <Styles screenHeight={window.innerHeight}>
      <div className="adventure">
        <Header>
          <h1
            className={headerClass}
            onClick={() => {
              setShowAdventurePanel(isTabletScreen());
            }}
          >
            {adventure.name}
          </h1>
          <Actions>
            {isPlayerCharacter &&
              unauthenticated &&
              adventure.transcript.messages.length > 0 && (
                <a href="/login" className="button primary">
                  Sign up to save your character
                </a>
              )}
            {isPlayerCharacter && !unauthenticated && (
              <div
                className="clickable action"
                id="share-button"
                onClick={() => setShowShareDialog(true)}
              >
                <FontAwesomeIcon icon={faShareFromSquare} />
              </div>
            )}
            {isPlayerCharacter &&
              character.isLeadCharacter &&
              !unauthenticated && (
                <div
                  className="clickable action"
                  id="campaign-settings-button"
                  onClick={() => setShowCampaignDialog(true)}
                >
                  <FontAwesomeIcon icon={faGear} />
                </div>
              )}
          </Actions>
        </Header>
        <CampaignDialog
          show={showCampaignDialog}
          campaignId={adventure.campaignId}
          onClose={() => setShowCampaignDialog(false)}
        />
        <ShareDialog
          show={showShareDialog && !showCampaignDialog}
          adventureName={adventure.name}
          onClose={() => setShowShareDialog(false)}
          characterId={character.id}
        />

        <div className="content-container">
          {showAdventurePanel && (
            <AdventurePanel
              adventure={adventure}
              character={character}
              pcStates={pcStates}
              onClose={() => setShowAdventurePanel(false)}
            />
          )}
          <div className="content">
            <Transcript
              adventure={adventure}
              character={character}
              isPlayerCharacter={isPlayerCharacter}
            />
          </div>
        </div>
        <Footer className={footerClass}>
          {isPlayerCharacter && (
            <AdventureFooter
              status={status}
              character={character}
              adventure={adventure}
              dmThinking={dmThinking}
              submitUserPrompt={submitUserPrompt}
            />
          )}
          {!isPlayerCharacter && <ViewerFooter adventure={adventure} />}
        </Footer>
      </div>
    </Styles>
  );
}

function ShareDialog({ show, onClose, characterId, adventureName }) {
  const [copied, setCopied] = useState(false);
  const shareUrl = characterUrl(characterId, true);

  useEffect(() => {
    setCopied(false);
  }, [show]);

  function copyToClipboard() {
    navigator.clipboard.writeText(shareUrl);
    setCopied(true);
  }

  return (
    <CenteredModal show={show} onClose={onClose}>
      <div className="share-dialog">
        <h1>Share this adventure</h1>
        <p>
          Send this link to your friends so they can create their own character
          and join <em>{adventureName}</em>.
        </p>
        <div className="share-url">
          <input type="text" value={shareUrl} readOnly />
        </div>
        <Actions style={{ marginTop: "1em", width: "100%" }}>
          {!copied && (
            <button onClick={copyToClipboard}>
              <FontAwesomeIcon icon={faClipboard} /> Copy
            </button>
          )}
          {copied && (
            <span>
              <FontAwesomeIcon
                icon={faClipboard}
                style={{ marginRight: "0.5em" }}
              />
              Copied!
            </span>
          )}
          <button onClick={onClose}>Close</button>
        </Actions>
      </div>
    </CenteredModal>
  );
}

function AdventureFooter({
  character,
  adventure,
  dmThinking,
  submitUserPrompt,
  status,
}) {
  const { apiClient, unauthenticated } = useContext(AuthContext);
  const [lastKeystroke, setLastKeystroke] = useState(0);

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current?.focus();
    if (Date.now() - lastKeystroke < 1000) return;

    apiClient.put(`/characters/${character.id}/typing`);
  }, [character, lastKeystroke, apiClient]);

  if (dmThinking || status === "Responding") {
    return <ThinkingDice fullWidth />;
  }

  if (status === "Paused" && !character.isLeadCharacter) {
    return (
      <span>This campaign is paused until the lead character returns.</span>
    );
  }

  // for unauthenticated users, at some point we need them to sign up
  // to continue.
  if (unauthenticated && adventure.transcript.messages.length > 6) {
    return (
      <>
        <span>Sign up to continue!</span>
        <Actions>
          <Auth
            providers={["google", "discord"]}
            onlyThirdPartyProviders={true}
            supabaseClient={supabase}
            appearance={{
              extend: true,
              className: {
                container: "auth",
                button: "auth-button",
              },
            }}
          />
        </Actions>
      </>
    );
  }

  if (!adventure.isRunning) {
    return (
      <Actions>
        <a href={characterUrl(character.id)}>
          <h1>To be continued...</h1>
        </a>
      </Actions>
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    const userPrompt = inputRef.current.value;
    submitUserPrompt(userPrompt);
    // clear the input field
    inputRef.current.value = "";
  }

  const placeholder = isCombat(adventure)
    ? "What do you and NPCs do this round?"
    : "What do you say?";
  return (
    <form className="footer-form" onSubmit={handleSubmit}>
      <Image id={character.imageId} className="portrait" />
      <h1>{character.name}</h1>
      <input
        ref={inputRef}
        placeholder={placeholder}
        onChange={() => setLastKeystroke(Date.now())}
      />
      {status !== "Ready" && (
        <Tooltip text="Thinking..." anchor="left">
          <ThinkingDice minimal />
        </Tooltip>
      )}
    </form>
  );
}

function ViewerFooter({ adventure }) {
  const [isCreating, setIsCreating] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { apiClient, user } = useContext(AuthContext);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  function createCharacterAndJoin() {
    setIsCreating(true);
    const prompt = inputRef.current.value || "Surprise Me";
    const body = { prompt };
    apiClient
      .post(`/campaigns/${adventure.campaignId}/join`, body)
      .then(({ data }) => {
        navigate(characterUrl(data.id));
      });
  }

  function joinClicked() {
    setShowForm(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }

  if (!user) {
    const redirectTo = window.location.pathname;
    const loginUrl = `/login?redirect=${encodeURIComponent(redirectTo)}`;
    return (
      <>
        <h3>Login now to join the adventure!</h3>
        <Actions>
          <a href={loginUrl} className="button primary">
            Login
          </a>
        </Actions>
      </>
    );
  }

  if (isCreating) {
    return <ThinkingDice fullWidth title="Creating character..." />;
  }

  if (showForm) {
    return (
      <form className="footer-form" onSubmit={createCharacterAndJoin}>
        <input
          ref={inputRef}
          placeholder="Describe your character (name, race, class, etc.)"
        />
        <button
          type="submit"
          className="primary"
          onClick={createCharacterAndJoin}
        >
          Create
        </button>
        <button type="button" onClick={() => setShowForm(false)}>
          Cancel
        </button>
      </form>
    );
  }

  return (
    <>
      <h3>You can create a character and join the adventure</h3>
      <Actions>
        <button className="primary" onClick={joinClicked}>
          Join
        </button>
      </Actions>
    </>
  );
}

function isCombat(adventure) {
  return adventure?.state === "Combat";
}

const Styles = styled.div`
  .adventure {
    height: 100vh;
    position: fixed;

    @media (max-width: 600px) {
      height: ${(props) => props.screenHeight}px;
    }

    .action {
      padding: 0.5em;
      margin-left: 0.5em;
    }

    display: flex;
    flex-direction: column;
    align-items: stretch;

    p {
      font-size: 24px;
    }

    .content-container {
      overflow-y: auto;
      flex-grow: 1;
    }

    .footer-form {
      align-items: center;
      display: flex;
      flex-direction: row;
      width: 100%;
      @media (max-width: 600px) {
        max-width: 100vw;
      }

      .portrait {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid ${Colors.border};
      }

      font-size: larger;

      h1 {
        font-size: 1em;
        @media (max-width: 600px) {
          display: none;
        }
        margin-left: 0.5em;
      }

      input {
        flex-grow: 1;
        touch-action: manipulation;
        font-size: 1em;
        resize: vertical;
        margin: 0.2em 0.5em;
        padding: 0.2em;
        @media (max-width: 600px) {
          margin: 0.5em;
          padding: 0.5em;
        }
      }
    }
    .status {
      padding: 0.5em 0;
      text-align: center;
      color: ${Colors.lighterText};
    }
  }

  .combat {
    animation: pulsate 2s ease-out infinite;
    color: #ccc;
  }

  @keyframes pulsate {
    0% {
      background-color: hsl(0, 50%, 40%);
    }

    50% {
      background-color: hsl(0, 50%, 50%);
    }

    100% {
      background-color: hsl(0, 50%, 40%);
    }
  }
  .share-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    max-width: 600px;

    input {
      font-size: 1.2em;
      width: 20em;
      max-width: 560px;
    }
  }

  .auth {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  button.auth-button {
    font-size: 0.7em;
  }
`;

/**
 save for if/when we bring back streaming

  function _submitUserPromptStreaming(e) {
    if (userPrompt.length < 3) return;

    e.preventDefault();
    setDmThinking(true);

    adventure.transcript.messages.push({
      id: "user-prompt",
      content: userPrompt,
      role: "pc",
      speaker: character.name,
    });

    const request = {
      method: "post",
      body: JSON.stringify({ prompt: userPrompt }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.access_token}`,
      },
    };
    fetchStream(
      `/adventures/${adventure.id}/nextStream`,
      request,
      (chunk) => {
        setPartialResponse((previous) => {
          if (previous) {
            return previous + chunk;
          } else {
            return chunk;
          }
        });
      },
      () => {
        setDmThinking(false);
        setPartialResponse((previous) => {
          return previous + "\n";
        });
        if (!isMobileScreen()) inputRef.current?.focus();
      }
    );
  }
*/
