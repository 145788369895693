import { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import { AuthContext } from "../AuthContext";
import OverlayDialog from "./OverlayDialog";
import Colors, { Actions, Card, Subtitle } from "../shared-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { characterUrl } from "./Main";
import { useNavigate } from "react-router-dom";
import CreateCharProgressBar from "./CreateCharProgressBar";

export default function CampaignDialog({ show, campaignId, onClose }) {
  const { apiClient } = useContext(AuthContext);
  const [campaign, setCampaign] = useState(null);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  function resetAdventure(e) {
    e.preventDefault();

    console.log("resetting adventure", campaign);
    const confirm = window.confirm(
      "Reset back to the begining of the adventure?"
    );
    if (!confirm) return;

    const adventureId = campaign.currentAdventureId;

    apiClient
      .post(`/adventures/${adventureId}/reset`)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function copyInvitation(e) {
    e.preventDefault();
    const shareUrl = characterUrl(campaign.mainCharacterId, true);
    const invitationText = `Join my campaign on RoleCraft: ${shareUrl}`;

    navigator.clipboard.writeText(invitationText);
    setCopied(true);
  }

  function deleteCampaign(e) {
    e.preventDefault();

    const confirm = window.confirm(
      "Delete this campaign? All characters and history will be lost."
    );
    if (!confirm) return;

    apiClient
      .delete(`/campaigns/${campaign.id}`)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (!apiClient || !campaignId) return;
    apiClient
      .get(`/campaigns/${campaignId}`)
      .then(({ data }) => {
        setCampaign(data);
        setCopied(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [apiClient, campaignId]);

  if (!campaign) return "";

  return (
    <OverlayDialog show={show} onClose={onClose} width="50%">
      <Styles>
        <Card>
          <h1>Campaign Settings</h1>
          <Subtitle>Specify how you want to run your campaign.</Subtitle>
          <hr />
          <CampaignForm campaign={campaign} onSubmit={onClose}>
            <>
              <Actions>
                {!copied && (
                  <button onClick={copyInvitation}>
                    <FontAwesomeIcon icon={faClipboard} />
                    &nbsp; Copy Invitation
                  </button>
                )}
                {copied && (
                  <span className="copied">
                    <FontAwesomeIcon icon={faClipboard} />
                    &nbsp; Copied Invitation
                  </span>
                )}
                <button type="button" onClick={onClose}>
                  Cancel
                </button>
                <button type="submit" className="primary">
                  Save
                </button>
              </Actions>
            </>
          </CampaignForm>
          <h2>Danger Zone</h2>
          <legend className="danger-zone">
            Proceed with caution. These actions cannot be undone!
          </legend>
          <Actions>
            <button type="button" className="warning" onClick={resetAdventure}>
              Reset Adventure
            </button>
            <button type="button" className="danger" onClick={deleteCampaign}>
              Delete Character and Campaign
            </button>
          </Actions>
        </Card>
      </Styles>
    </OverlayDialog>
  );
}

/**
 * this is shown while the campaign is being created, so it
 * will poll until the campaign is ready before showing actions
 */
const RETRY_TIMEOUT = 30000;
export function CampaignEditor({ campaignId, onSubmit, onRetry, onDelete }) {
  const { apiClient } = useContext(AuthContext);
  const [campaign, setCampaign] = useState(null);
  const [status, setStatus] = useState(null);

  // poll the campaign until it's ready
  useEffect(() => {
    if (!apiClient || !campaignId) return;
    const interval = setInterval(() => {
      apiClient
        .get(`/campaigns/${campaignId}`)
        .then(({ data }) => {
          console.log("campaign", data);
          if (data.status !== status) {
            setStatus(data.status);
            setCampaign(data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }, 1000);

    if (status === "Ready") {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [apiClient, campaignId, status]);

  if (!campaign) return "";
  const showRetry =
    status === "Error" ||
    (status !== "Ready" && Date.now() - campaign.updatedAt > RETRY_TIMEOUT);

  return (
    <Styles>
      <Card>
        {campaign.status !== "Ready" && (
          <>
            <h1>Dreaming up your first adventure.</h1>
            {campaign.status !== "Ready" && campaign.status !== "Error" && (
              <Subtitle>Status: {campaign.status}...</Subtitle>
            )}
            <legend>While you are waiting you can set up your campaign.</legend>
            <CreateCharProgressBar
              characterId={campaign.mainCharacterId}
              status={status}
            />
          </>
        )}
        {campaign.status === "Ready" && (
          <>
            <h1>Ready to Play!</h1>
            <Subtitle>
              Your campaign is ready to play. You can still edit your campaign
              settings.
            </Subtitle>
          </>
        )}
        <CampaignForm campaign={campaign} onSubmit={onSubmit}>
          <>
            {campaign.status === "Ready" && (
              <>
                <Actions style={{ marginTop: "0.5em" }}>
                  <button className="primary" type="button" onClick={onSubmit}>
                    Begin your first adventure
                  </button>
                </Actions>
              </>
            )}
            {showRetry && (
              <>
                <legend className="error">
                  Oops! it looks like there was an error creating your
                  adventure.
                </legend>
                <Actions style={{ marginTop: "0.5em" }}>
                  <button
                    type="button"
                    className="danger"
                    onClick={() => {
                      console.log("delete");
                      onDelete();
                    }}
                  >
                    Delete Character
                  </button>
                  <button type="button" className="primary" onClick={onRetry}>
                    Try Creating Your Adventure Again.
                  </button>
                </Actions>
              </>
            )}
          </>
        </CampaignForm>
      </Card>
    </Styles>
  );
}

export function CampaignForm({ campaign, onSubmit, children }) {
  const { apiClient } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({
    ...campaign.settings,
  });

  if (!campaign) return null;

  function PermissionsRadio({ name, label }) {
    function onChange(e) {
      setFormValues({ ...formValues, permissions: e.target.value });
    }
    return (
      <label htmlFor={name}>
        <input
          type="radio"
          name="permissions"
          id={name}
          value={name}
          onChange={onChange}
          checked={formValues.permissions === name}
        />
        {label}
      </label>
    );
  }

  function updateInput(e) {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: parseInt(value) });
  }

  function submitSettings(e) {
    e.preventDefault();
    apiClient.post(`/campaigns/${campaign.id}/settings`, formValues);
    onSubmit();
  }

  return (
    <form onSubmit={submitSettings}>
      {children}
      <h2>Permissions</h2>
      <PermissionsRadio
        name="publicReadWrite"
        label="Public: Show your campaign in the LFG list"
      />
      <PermissionsRadio
        name="privateMultiPlayer"
        label="Private: Only friends with your URL can join"
      />
      <legend>
        Any characters currently in your campaign will remain in it until you
        explicitly dismiss them. As campaign owner, you can remove anybody from
        your campaign at any time.
      </legend>
      <h2>Party</h2>

      <label htmlFor="maxPcs">
        <input
          value={formValues.maxPcs}
          onChange={updateInput}
          type="number"
          name="maxPcs"
          min={1}
          max={6}
        />
        Max player characters
      </label>
      <label htmlFor="maxNpcs">
        <input
          type="number"
          onChange={updateInput}
          value={formValues.maxNpcs}
          name="maxNpcs"
          min={0}
          max={8}
        />
        Max # NPCs
      </label>
      <legend>
        You can dismiss NPCs or even PCs at any time. If you set a limit below
        the number of members currently in your party, they will remain until
        you explicitly dismiss them.
      </legend>

      <h2>Gameplay</h2>
      <label htmlFor="playerTimeout">
        Player timeout:
        <input
          value={formValues.playerTimeoutMinutes}
          type="number"
          name="playerTimeoutMinutes"
          onChange={updateInput}
          min="1"
          max="30"
        />
        minutes
      </label>
      <legend>
        If a player is not active in this campaign for this period of time, they
        are considered to be away from the game. After that point, the the
        character will remain in the story, but will be become an NPC until the
        player returns.
      </legend>
      <legend>
        As party leader, you must be present for the campaign to continue. There
        is no Lord of the Rings without Frodo! Once you timeout, the campaign
        will be suspended until you return.
      </legend>
    </form>
  );
}

const Styles = styled.div`
  form {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;
      input {
        margin-right: 0.5em;
      }
    }

    // only number inputs
    input[type="number"] {
      padding: 0.2em;
      margin: 0.2em;
      font-size: 0.8em;
      width: 2.5em;
    }

    hr {
      width: 100%;
      margin: 1rem 0;
    }
  }

  h1 {
    font-size: 1.3em;
    margin-top: 0;
  }

  h2 {
    width: 100%;
    border-top: 1px solid ${Colors.border};
    margin-top: 0.5em;
    font-size: 1.1em;
  }

  legend {
    color: ${Colors.lighterText};
    padding: 0;
    margin-left: 0;
    margin-top: 0.5em;
    font-size: 0.7em;
    font-style: italic;
  }
  legend.error {
    color: orange;
  }

  legend.danger-zone {
    margin-top: 0.2em;
    margin-bottom: 1em;
  }
  button {
    font-size: 0.7em;
    margin-top: 0.3em;
  }
`;
