import { toast } from "react-toastify";
import styled from "styled-components";
import { useState, useContext } from "react";

import OverlayDialog from "./OverlayDialog";
import { Actions, Paragraphs } from "../shared-components";
import { AuthContext } from "../AuthContext";

export default function FeedbackDialog({
  adventureId,
  itemId,
  content,
  show,
  onClose,
}) {
  const [userFeedback, setUserFeedback] = useState("");
  const { apiClient } = useContext(AuthContext);

  return (
    <OverlayDialog show={show} onClose={onClose} title="Feedback">
      <Styles>
        <h1>Feedback</h1>
        <p>
          Help us improve the game by telling us what was wrong with this DM's
          response. You can also delete this response from your adventure
          transcript and try again.
        </p>
        <textarea
          onChange={(e) => setUserFeedback(e.target.value)}
          value={userFeedback}
        />
        <Actions>
          <button onClick={onClose}>Cancel</button>
          <button onClick={() => submitFeedback(false)}>Submit</button>
          <button onClick={() => submitFeedback(true)}>
            Submit and Delete
          </button>
        </Actions>
        <hr />
        <div className="content">
          <Paragraphs text={content} />
        </div>
      </Styles>
    </OverlayDialog>
  );

  function submitFeedback(deleteItem) {
    const body = {
      adventureId,
      itemId,
      feedback: userFeedback,
      deleteItem,
    };

    console.log("submitting feedback", body);
    onClose();
    apiClient
      .post(`/chatGptLogs/feedback`, body)
      .then(() => {
        toast.info("Thanks for your feedback!");
      })
      .catch((err) => {
        toast.error("Sorry, there was an error submitting your feedback.");
      });
  }
}

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 1em;

  textarea {
    padding: 0.5em;
    height: 200px;
    font-size: larger;
    font-family: "Garamond", serif;
    margin-bottom: 1em;
  }

  .content {
    p {
      font-size: smaller;
    }
  }

  button {
    font-size: small;
  }
`;
