import styled from "styled-components";
import Colors, { Header, Content, Actions } from "../shared-components";
import { AuthContext } from "../AuthContext";
import { useContext, useRef, useState } from "react";

export default function UpgradePlan({ plan, playerId, user }) {
  const [submitted, setSubmitted] = useState(false);
  const whyRef = useRef(null);
  const howRef = useRef(null);

  const { apiClient } = useContext(AuthContext);

  function submitRequest(e) {
    e.preventDefault();
    const whyInterested = whyRef.current.value;
    const howDidYouHear = howRef.current.value;
    const body = {
      whyInterested,
      howDidYouHear,
    };

    console.log(body);
    debugger;

    apiClient
      .post("/request-access", body)
      .then(() => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }

  return (
    <Styles>
      <Header>
        <h1>Request Access</h1>
      </Header>
      <Content className="content">
        <h2>Sorry, you can't create your own campaign yet.</h2>
        <div className="waiting">
          <img src="/images/waiting.jpg" alt="Rolecraft" />
          <p>
            Rolecraft is currently in a limited private beta. During this time,
            full access is limited to a small number of users. Full access will
            enable you to create your own characters and campaigns, and invite
            your friends to play with you.
          </p>
          <h4>Now here's the good news.</h4>
          <p>
            You can still play right by joining another campaign. You can view
            the list of campaigns that have activity right now by visiting the{" "}
            <a href="/campaigns/lfg">Looking for Group</a> page.
          </p>
          <p>
            If you're interested in getting full access, please tell us a little
            bit about yourself and why you're excited to try Rolecraft. We'll
            get back to you as soon as we can.
          </p>
        </div>
        <hr />
        {!submitted && (
          <form onSubmit={submitRequest}>
            <input
              ref={howRef}
              type="text"
              name="howDidYouHear"
              placeholder="How did you hear about Rolecraft?"
            />
            <input
              ref={whyRef}
              type="text"
              name="whyInterested"
              placeholder="Tell us about why you're excited to try Rolecraft"
            />
            <Actions className="actions">
              <button className="primary" onClick={submitRequest}>
                Request Access
              </button>
            </Actions>
          </form>
        )}
        {submitted && (
          <div className="submitted">
            <h2>Thanks for your interest!</h2>
            <p>
              We'll get back to you as soon as we are ready for you to join.
            </p>
            <Actions className="actions">
              <a href="/campaigns/lfg" className="button primary">
                Find a Campaign to Join
              </a>
            </Actions>
          </div>
        )}
      </Content>
    </Styles>
  );
}

const Styles = styled.div`
  .content {
    display: flex;
    flex-direction: column;

    max-width: 600px;
    margin: 0 auto;

    .waiting {
      display: block;
      img {
        width: clamp(200px, 40%, 400px);
        float: right;
        margin: 1em 0 1em 1em;
        border: 3pg groove ${Colors.border};
      }
    }

    hr {
      width: 90%;
      margin-bottom: 1em;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .actions {
        width: 100%;
      }
      input {
        margin: 1em 0;
        padding: 0.5em;
        font-size: 1em;
      }
    }
  }
`;
