import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faDiceD20,
  faFileText,
  faQuestion,
  faSignOut,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import Colors, { Tooltip, isMobileScreen } from "../shared-components";
import { NavLink } from "react-router-dom";
import {
  AuthContext,
  getUnauthenticatedPlayerId,
  setUnauthenticatedPlayerId,
} from "../AuthContext";
import Image from "./Image";
import { useContext, useState, useEffect } from "react";

export const PLANS = {
  0: "Free",
  1: "Basic",
  2: "Premium",

  Free: 0,
  Basic: 1,
  Premium: 2,
  Admin: 100,
};

export default function Nav() {
  const [characters, setCharacters] = useState(null);
  const [menuButtonVisible, setMenuButtonVisible] = useState(isMobileScreen());
  const { apiClient, userId } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    if (!apiClient || !userId) return;
    let url = `/characters`;
    const unauthPlayerId = getUnauthenticatedPlayerId();
    if (unauthPlayerId) {
      url = url + `?unauthPlayerId=${unauthPlayerId}`;
    }
    apiClient.get(url).then(({ data }) => {
      // filter out characters that don't have a campaignId
      // they are test characters for building game systems
      // and show in reverse order, newest characters first
      setCharacters(data.filter((c) => c.campaignId).reverse());

      // clear the unauthPlayerId
      setUnauthenticatedPlayerId(null);
      return data;
    });

    apiClient.get(`/me`).then(({ data }) => {
      setPlan(data.plan);
      setIsAdmin(data.plan === PLANS.Admin);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, userId]);

  if (!characters) return "";
  return (
    <Styles>
      {menuButtonVisible && (
        <NavMenuButton onClick={() => setMenuButtonVisible(false)} />
      )}
      {!menuButtonVisible && (
        <div
          className="nav-container"
          onClick={() => setMenuButtonVisible(isMobileScreen())}
        >
          <div className="nav">
            <div className="main-section">
              {characters.map((c) => (
                <CharacterNavItem character={c} key={c.id} />
              ))}
              {characters.length > 0 && <hr />}
              <NavItem to="/characters/new" toolTip="New Character">
                <img
                  src={"/images/new-character-small.jpg"}
                  alt="New Character"
                />
              </NavItem>
              <NavItem to="/campaigns/lfg" toolTip="Join a Campaign">
                <img
                  src={"/images/join-campaign-small.jpg"}
                  alt="New Character"
                />
              </NavItem>
            </div>
            <ConfigNavSection isAdmin={isAdmin} plan={plan} />
          </div>
        </div>
      )}
    </Styles>
  );
}

function NavItem({ children, to, toolTip }) {
  return (
    <NavLink className="nav-item clickable" to={to}>
      <Tooltip text={toolTip} anchor="right">
        {children}
      </Tooltip>
    </NavLink>
  );
}

function CharacterNavItem({ character }) {
  return (
    <NavItem to={`/${character.id}`} toolTip={character.name}>
      <Image id={character.imageId || character.dalleImageId} />
    </NavItem>
  );
}

function ConfigNavSection({ isAdmin, plan }) {
  return (
    <div className="config-section">
      <NavItem toolTip="logout" to="/logout">
        <FontAwesomeIcon icon={faSignOut} />
      </NavItem>
      <NavItem toolTip="About" to="/help">
        <FontAwesomeIcon icon={faQuestion} />
      </NavItem>

      {plan >= PLANS.Premium && (
        <NavItem toolTip="Game Systems" to="/gamesystems/new">
          <FontAwesomeIcon icon={faDiceD20} />
        </NavItem>
      )}

      {plan === PLANS.Admin && (
        <>
          <hr />
          <NavItem toolTip="Players" to="/admin/players">
            <FontAwesomeIcon icon={faUserGroup} />
          </NavItem>
          {/* <NavItem toolTip="Wait List" to="/admin/waitlist">
            <FontAwesomeIcon icon={faUserClock} />
          </NavItem> */}

          <NavItem toolTip="KV" to="/admin/kv">
            <FontAwesomeIcon icon={faDatabase} />
          </NavItem>
          <NavItem toolTip="ChatGPT Logs" to="/admin/chatLogs">
            <FontAwesomeIcon icon={faFileText} />
          </NavItem>
        </>
      )}
    </div>
  );
}

function NavMenuButton({ onClick }) {
  return (
    <div className="nav-button-container">
      <div className="nav-button" onClick={onClick} />
    </div>
  );
}

const leftNavWidth = 64;
const navImageWidth = leftNavWidth - 20;
const Styles = styled.div`
  // for mobile ui. Invisible button that overlays the character
  // image to show the nav menu when clicked
  .nav-button-container {
    position: absolute;
    height: 100vh;
    pointer-events: none;

    .nav-button {
      pointer-events: all;
      position: fixed;
      z-index: 100;
      font-size: 1.5em;
      width: 80px;
      height: 60px;
      bottom: 0px;
      left: 0px;
    }
  }

  .nav-container {
    height: 100%;
    ${isMobileScreen() &&
    `
    position: fixed;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99;
    `}
  }

  .nav {
    width: ${leftNavWidth}px;
    height: 100%;
    background-color: ${Colors.altBackgroundDarker};
    border-right: 1px solid ${Colors.border};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    ${isMobileScreen() && `position: fixed;`}

    hr {
      margin: 0.2em 0.1em;
      padding: 0 0.1em;
    }

    .nav-item {
      padding: 0.4em;
      text-align: center;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;

      img {
        width: ${navImageWidth}px;
        height: ${navImageWidth}px;

        border-radius: 50%;
      }

      h3 {
        font-weight: 600;
        font-size: 12px;
      }

      a {
        color: ${Colors.lighterText};
      }
      a:visited {
        color: ${Colors.lighterText};
      }
      &.clickable:hover {
        background-color: ${Colors.altBackgroundLighter};
      }
    }

    .active {
      border-left: 4px solid ${Colors.selected};
    }

    // these are typically icons in the config section items
    svg {
      font-size: 20px;
      color: ${Colors.lighterText};
      padding-top: 0.2em;
      padding-bottom: 0.2em;
    }

    .main-section {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    .config-section {
      color: ${Colors.lighterText};
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;
