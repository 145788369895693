import styled from "styled-components";
import { Body, Header, Content } from "../shared-components";

export default function WaitList() {
  return (
    <Body>
      <Styles>
        <Header className="transparent">
          <h1>Rolecraft</h1>
          <h2>AI Based Roleplaying Game Platform</h2>
        </Header>
        <Content className="content">
          <h1>Thank You for Signing Up.</h1>
          <p>
            Rolecraft is currently in closed beta. You are currently on the
            waiting list. We will let you know when we're ready for you!
          </p>
        </Content>
      </Styles>
    </Body>
  );
}

const Styles = styled.div`
  background-image: url("/images/cover-art-small.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;

  .transparent {
    background-color: transparent;
    border-bottom: none;
    color: white;
    text-shadow: 0 0 10px black;
  }

  .content {
    position: fixed;
    left: 50vw;
    top: 30vh;
    transform: translate(-50%, -50%);
    display: block;
    align-items: center;
    padding: 0;
    margin: 0;
    color: white;
    text-shadow: 0 0 10px black;

    h1 {
      font-size: 3em;
    }
    p {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .center {
    text-align: center;
    width: 100%;
  }
`;
