import styled from "styled-components";

export default function ThinkingDice({ title, fullWidth, minimal, noPicture }) {
  if (minimal) {
    return (
      <Styles>
        <img src="/images/rolling-dice.gif" className="rolling-dice" alt="DM" />
      </Styles>
    );
  }

  return (
    <Styles fullWidth={fullWidth}>
      {!noPicture && (
        <img
          src="/images/DungeonMaster.jpg"
          width="40px"
          height="40px"
          alt="DM"
        />
      )}

      <div className={`dice`}>
        <img
          src="/images/rolling-dice.gif"
          className="rolling-dice"
          alt="Dice"
        />
      </div>
      {title && <h3 className="title">{title}</h3>}
    </Styles>
  );
}

const Styles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => (props.fullWidth ? "width: 100%;" : "")}

  img.rolling-dice {
    width: 40px;
    height: 40px;
  }

  .dice {
    flex-grow: 1;

    // animate the location of the image going from left to right and back in a loop
    img {
      position: relative;
      padding-left: 18px;
      margin-left: 8px;
      padding-right: 18px;

      animation: roll 15s linear infinite;
      z-index: 0;
    }

    h3.title {
      margin: 0;
      top: 0;
      text-align: center;
      z-index: 1;
      margin-right: 10px;
      margin-left: 10px;
    }

    @keyframes roll {
      0% {
        left: 0;
      }

      50% {
        left: calc(100% - 58px);
      }

      100% {
        left: 0;
      }
    }
  }
`;
