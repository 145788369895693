import { useContext, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import styled from "styled-components";

import { Body } from "../shared-components";
import { AuthContext } from "../AuthContext";

import CreateCharacter from "./CreateCharacter";
import Character from "./Character";
import DefaultCharacter from "./DefaultCharacter";
import KeySpace from "../admin/KeySpace";
import ChatGptLogs from "../admin/ChatGptLogs";
import Help from "./Help";
import GameSystemBuilder from "./GameSystemBuilder";
import NewGameSystem from "./NewGameSystem";
import Players from "../admin/Players";
import WaitList from "./WaitList";
import WaitListAdmin from "../admin/WaitListAdmin";
import CreateCampaign from "./CreateCampaign";
import LookingForGroup from "./LookingForGroup";
import Campaign from "./Campaign";
import SignupNow from "./SignupNow";
import Login, { Logout } from "./Login";
import NotFound from "./NotFound";
import Nav, { PLANS } from "./Nav";

export default function Main() {
  const [isWaitList, setIsWaitList] = useState(false);
  const { apiClient, user } = useContext(AuthContext);
  const [plan, setPlan] = useState(PLANS.Free);

  useEffect(() => {
    if (!apiClient || !user) return;
    apiClient
      .get("/me")
      .then(({ data }) => {
        setPlan(data.plan);
      })
      .catch((err) => {
        console.log(err);
        // 409 means the user is on the waiting list
        if (err?.response?.status === 409) {
          setIsWaitList(true);
        }
        console.log(err);
      });
  }, [apiClient, user]);

  // if (loading) return "";
  if (isWaitList && user) {
    // if the path is "/logout" we need to logout, otherwise show the
    // waitlist page
    if (window.location.pathname === "/logout") {
      return <Logout />;
    }
    return <WaitList />;
  }

  return (
    <BrowserRouter>
      <Body>
        <Styles>
          <div className="main-layout">
            {user && <Nav />}

            <div className="main-content">
              <Routes>
                <Route exact path="/" element={<DefaultCharacter />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/help" element={<Help />} />
                <Route path="/try" element={<CreateCharacter />} />

                <Route path="/characters/">
                  <Route path="new" element={<CreateCharacter />} />
                  <Route path=":id" element={<Character />} />
                  <Route
                    path=":id/create-campaign"
                    element={<CreateCampaign />}
                  />
                  <Route path=":id/signup-now" element={<SignupNow />} />
                </Route>
                <Route path="/campaigns/">
                  <Route path="lfg" element={<LookingForGroup />} />
                  <Route path=":id" element={<Campaign />} />
                </Route>
                {plan >= PLANS.Premium && (
                  <Route path="/gamesystems/">
                    <Route path="new" element={<NewGameSystem />} />
                    <Route
                      path=":handle/edit"
                      element={<GameSystemBuilder />}
                    />
                  </Route>
                )}
                {plan >= PLANS.Admin && (
                  <Route path="/admin/">
                    <Route path="kv" element={<KeySpace />} />
                    <Route path="kv/:keySpace" element={<KeySpace />} />
                    <Route path="players" element={<Players />} />
                    <Route path="waitlist" element={<WaitListAdmin />} />
                    <Route path="chatLogs" element={<ChatGptLogs />} />
                  </Route>
                )}

                <Route path="/:id" element={<Character />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </Styles>
      </Body>
    </BrowserRouter>
  );
}

export function characterUrl(characterId, withHost = false) {
  if (withHost) {
    return `${window.location.origin}/${characterId}`;
  }
  return `/${characterId}`;
}

const Styles = styled.div`
  .main-layout {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;

    .main-content {
      width: 100%;
      height: 100vh;
      overflow-y: auto;
    }
  }
`;
