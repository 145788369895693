import styled from "styled-components";
import Colors from "../shared-components";

export default function GameSystemCard({
  name,
  description,
  selected,
  onClick,
}) {
  const className = selected
    ? "gamesystem selected clickable"
    : "gamesystem clickable";
  return (
    <Styles>
      <div className={className} onClick={onClick}>
        <h1>{name}</h1>
        <h3>{description}</h3>
      </div>
    </Styles>
  );
}

const Styles = styled.div`
  .gamesystem {
    cursor: pointer;
    padding: 0.5em;
    h1 {
      margin: 0;
      font-size: unset;
    }
    h3 {
      font-size: unset;
    }
  }
  .selected {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid ${Colors.border};
  }
`;
