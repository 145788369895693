interface ColorPalette {
  background: string;
  contrastBackground: string;
  color: string;
  contrastColor: string;
  lighterText: string;
  darkerText: string;
  border: string;
  selected: string;
  altBackgroundDarker: string;
  altBackgroundLighter: string;
  headerBackground: string;
  headerBorder: string;
  primaryBorder: string;
  primaryBackground: string;
  deleteBackground: string;
  deleteBorder: string;
}

const DarkColors: ColorPalette = {
  background: "#444",
  contrastBackground: "#EEE",

  color: "#EEE",
  contrastColor: "#222",
  lighterText: "#bbb",
  darkerText: "#FFF",
  border: "#888",

  selected: "yellow",

  altBackgroundDarker: "#333",
  altBackgroundLighter: "#555",

  headerBackground: "#222",
  headerBorder: "#444",

  primaryBorder: "#6699FF",
  primaryBackground: "#88AACC",

  deleteBackground: "#600",
  deleteBorder: "#a00",
};

const LightColors: ColorPalette = {
  background: "#F5F5F5",
  contrastBackground: "#444444",
  color: "#424242",

  contrastColor: "#FFFFFF",
  lighterText: "#777777",
  darkerText: "#222222",
  border: "#BDBDBD",
  selected: "#1b607b",

  altBackgroundDarker: "#EEEEEE",
  altBackgroundLighter: "#F9F9F9",

  headerBackground: "#FAFAFA",
  headerBorder: "#E0E0E0",

  primaryBorder: "#4488FF",
  // primaryBackground: "#0044aa",
  primaryBackground: "rgba(0, 68, 170, 0.2)",

  deleteBackground: "#ffe8e8",
  deleteBorder: "#d7a0a0",
};

export const Themes = {
  dark: DarkColors,
  light: LightColors,
  default: LightColors,
};
