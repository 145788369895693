import { useRef } from "react";
import styled from "styled-components";
import Colors from "../shared-components";

export default function CenteredModal({ children, onClose, show }) {
  const overlayRef = useRef(null);
  if (!show || !children) return "";

  return (
    <Styles>
      <div
        className="overlay"
        ref={overlayRef}
        onClick={(e) => {
          e.preventDefault();
          // only close when clicking on the background overlay; ignore
          // clicks on children
          if (e.target === overlayRef.current) {
            onClose();
          }
        }}
      >
        <div className="modal" onClick={() => {}}>
          <div className="content">{children}</div>
        </div>
      </div>
    </Styles>
  );
}

const Styles = styled.div`
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;

    .modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${Colors.altBackgroundDarker};
      box-shadow: 0 0 -10px rgba(0, 0, 0, 0.5);
      z-index: 100;

      @media (max-width: 600px) {
        max-width: 90%;
        max-height: 90%;
        top: 5%;
        transform: translateX(-50%);
        overflow-y: scroll;
      }
    }
  }
`;
