import { useContext, useEffect } from "react";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";

export default function DefaultCharacter() {
  const { apiClient, unauthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!apiClient || !navigate) return;
    apiClient
      .get(`/characters/current`)
      .then(({ data }) => {
        const character = data;
        navigate(`/${character.id}`);
      })
      .catch(() => {
        if (unauthenticated) {
          navigate("/try");
        } else {
          navigate("/characters/new");
        }
      });
  }, [apiClient, navigate, unauthenticated]);

  return "";
}
