import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../AuthContext";

import styled from "styled-components";
import CharacterDialog from "./CharacterDialog";
import AdventureDialog from "./AdventureDialog";
import Image from "./Image";
import Colors, {
  Paragraphs,
  Tooltip,
  isMobileScreen,
} from "../shared-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faKeyboard,
  faBed,
} from "@fortawesome/free-solid-svg-icons";
import CenteredModal from "./CenteredModal";

export default function AdventurePanel({
  adventure,
  character,
  onClose,
  pcStates,
}) {
  const [pcs, setPcs] = useState(null);
  const [npcs, setNpcs] = useState(null);
  const { apiClient } = useContext(AuthContext);
  const isInCombat = adventure?.currentEncounterId ? true : false;

  useEffect(() => {
    if (!apiClient || !adventure) return;
    apiClient.get(`/adventures/${adventure.id}/characters`).then(({ data }) => {
      const pcs = data.filter((c) => !c.isNPC);
      const npcs = data.filter((c) => c.isNPC);
      setPcs(pcs);
      setNpcs(npcs);
    });
  }, [apiClient, adventure]);
  const [showCharacter, setShowCharacter] = useState(false);

  const charSectionTitle = character.isViewOnly
    ? "Lead Character"
    : "Your Hero";

  return (
    <AdventurePanelContainer>
      {showCharacter && (
        <CharacterDialog
          character={showCharacter}
          onClose={() => setShowCharacter(null)}
        />
      )}
      <Styles>
        {onClose && (
          <button className="close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        )}
        <Section title={charSectionTitle} />
        <CharacterItem
          character={character}
          state={pcStates[character.id]}
          setShowCharacter={setShowCharacter}
        />
        <PcsSection
          adventure={adventure}
          character={character}
          pcs={pcs}
          pcStates={pcStates}
          setShowCharacter={setShowCharacter}
        />
        <NpcsSection
          adventure={adventure}
          npcs={npcs}
          setShowCharacter={setShowCharacter}
        />
        {isInCombat && <CombatEncounterSection adventureId={adventure.id} />}

        {!isInCombat && (
          <>
            <Section title="The Adventure" />
            <AdventureItem adventure={adventure} />
            <OtherAdventuresSection adventure={adventure} />
          </>
        )}
      </Styles>
    </AdventurePanelContainer>
  );
}

function Section({ title }) {
  return (
    <div className="section">
      <h1>{title}</h1>
    </div>
  );
}

function CharacterItem({ character, state, setShowCharacter }) {
  if (!character.isNPC) state = state || "afk";
  let className = character.isLeadCharacter
    ? `content character lead-character ${state}`
    : `content character ${state}`;
  const icon = state === "typing" ? faKeyboard : state === "afk" ? faBed : null;

  return (
    <div
      className="item panel-item clickable"
      onClick={() => setShowCharacter(character)}
    >
      <div className={className}>
        <Image id={character.imageId || character.dalleImageId} width={128} />
        <div className="info">
          <h1>
            {character.name}
            {icon && <FontAwesomeIcon className="state-icon" icon={icon} />}
          </h1>
          <h3>{character.summary}</h3>
        </div>
      </div>
      <StatusBar
        currentValue={character.characterSheet.hp}
        maxValue={character.characterSheet.maxHp}
        color="#880000"
        suffix={`/${character.characterSheet.maxHp} HP`}
      />
      {!character.isNPC && (
        <StatusBar
          currentValue={character.percentToNextLevel}
          maxValue={100}
          color="#000088"
          suffix="% to next level"
        />
      )}
    </div>
  );
}

function AdventureItem({ adventure }) {
  const [showAdventure, setShowAdventure] = useState(false);
  if (!adventure) return "";

  return (
    <>
      {showAdventure && (
        <AdventureDialog
          adventure={adventure}
          onClose={() => setShowAdventure(false)}
        />
      )}
      <div
        className="adventure-panel-item item clickable"
        onClick={() => setShowAdventure(!isMobileScreen())}
      >
        <h1>{adventure.name}</h1>
        <div className="cover-art-container">
          {adventure.imageId && (
            <div className="cover-art">
              <Image id={adventure.imageId} framed />
              <p>{adventure.introduction}</p>
              {isMobileScreen() && (
                <>
                  <h1>Story So Far</h1>
                  <Paragraphs text={adventure.storySummary} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function CombatEncounterSection({ adventureId }) {
  const [encounter, setEncounter] = useState(null);
  const { apiClient } = useContext(AuthContext);

  useEffect(() => {
    if (!adventureId || !apiClient) return;

    const interval = setInterval(() => {
      apiClient
        .get(`/adventures/${adventureId}/currentEncounter`)
        .then(({ data }) => {
          setEncounter(data);
        });
    }, 1000);

    return () => clearInterval(interval);
  }, [apiClient, adventureId]);

  if (!encounter) return "";
  return (
    <>
      <Section title="Combat Encounter" />
      {encounter.enemies.map((enemy) => (
        <EnemyItem enemy={enemy} key={enemy.id} />
      ))}
    </>
  );
}

function EnemyItem({ enemy }) {
  const [showEnemy, setShowEnemy] = useState(false);

  return (
    <>
      {showEnemy && (
        <CenteredModal onClose={() => setShowEnemy(false)}>
          <div className="enemy-dialog">
            <h1>{enemy.name}</h1>
            <Image id={enemy.imageId} width={128} />
            <h3>{enemy.status}</h3>
            <Paragraphs text={enemy.description} />
          </div>
        </CenteredModal>
      )}
      <div
        className="item panel-item clickable"
        onClick={() => setShowEnemy(true)}
        key={enemy.id}
      >
        <div className="content character">
          <Image id={enemy.imageId} width={128} />
          <div className="info">
            <h1>{enemy.name}</h1>
            <h3>{enemy.status}</h3>
          </div>
        </div>
        <StatusBar
          currentValue={enemy.hitPoints}
          maxValue={enemy.maxHitPoints}
          color="#880000"
          suffix={`/${enemy.maxHitPoints} HP`}
        />
      </div>
    </>
  );
}
function StatusBar({ currentValue, maxValue, color, suffix }) {
  if (currentValue < 0) currentValue = 0;
  if (currentValue > maxValue) currentValue = maxValue;

  const percentage = (currentValue / maxValue) * 100;
  const valueWidth = `${percentage}%`;

  return (
    <Tooltip text={`${currentValue}${suffix || ""}`}>
      <div
        style={{
          height: "8px",
          margin: "4px 8px 0px 8px",
          borderRadius: "4px",
          backgroundColor: Colors.border,
          boxShadow: `inset 0 0 2px 2px ${color}`,
        }}
      >
        <div
          style={{
            width: valueWidth,
            height: "100%",
            backgroundColor: color,
            float: "left",
            borderRadius: "4px",
          }}
        />
      </div>
    </Tooltip>
  );
}

function NpcsSection({ npcs, setShowCharacter }) {
  if (!npcs || npcs.length === 0) return "";
  return (
    <>
      <Section title="NPCs" />
      {npcs.map((npc) => (
        <CharacterItem
          character={npc}
          key={npc.id}
          setShowCharacter={setShowCharacter}
        />
      ))}
    </>
  );
}

function PcsSection({ pcs, character, pcStates, setShowCharacter }) {
  const leadCharacter = pcs && pcs.find((c) => c.isLeadCharacter);
  if (pcs) {
    pcs = pcs.filter((c) => c.id !== character?.id && !c.isLeadCharacter);
  }

  if (!pcs && !leadCharacter) return "";
  return (
    <>
      {leadCharacter && leadCharacter.id !== character?.id && (
        <>
          <Section title="Lead Character" />
          <CharacterItem
            character={leadCharacter}
            state={pcStates[leadCharacter.id]}
          />
        </>
      )}

      {pcs.length > 0 && (
        <>
          <Section title="Other PCs" />
          {pcs.map((pc) => (
            <CharacterItem
              character={pc}
              setShowCharacter={setShowCharacter}
              key={pc.id}
              state={pcStates[pc.id]}
            />
          ))}
        </>
      )}
    </>
  );
}

function OtherAdventuresSection({ adventure }) {
  const [otherAdventures, setOtherAdventures] = useState(null);
  const { apiClient } = useContext(AuthContext);
  const campaignId = adventure && adventure.campaignId;
  const adventureId = adventure && adventure.id;
  useEffect(() => {
    if (!adventureId || !apiClient || !campaignId) return;
    apiClient.get(`/campaigns/${campaignId}/adventures`).then(({ data }) => {
      setOtherAdventures(data.filter((a) => a.id !== adventureId));
    });
  }, [apiClient, campaignId, adventureId]);

  if (!otherAdventures || otherAdventures.length === 0) return "";

  return (
    <>
      <div className="section">
        <h1>Previous Adventures</h1>
      </div>
      {otherAdventures &&
        otherAdventures.map((a) => <OtherAdventureItem key={a.id} {...a} />)}
    </>
  );
}

function OtherAdventureItem({ name, introduction, imageId, id }) {
  const [adventure, setAdventure] = useState(null);
  const { apiClient } = useContext(AuthContext);

  function showAdventure() {
    apiClient.get(`/adventures/${id}`).then(({ data }) => {
      setAdventure(data);
    });
  }

  return (
    <>
      {adventure && (
        <AdventureDialog
          adventure={adventure}
          onClose={() => setAdventure(null)}
        />
      )}
      <div className="item panel-item clickable" onClick={showAdventure}>
        <div className="content adventure">
          <Image id={imageId} width={48} framed />
          <div className="info">
            <h1>{name}</h1>
            <h3>{introduction}</h3>
          </div>
        </div>
      </div>
    </>
  );
}

// If on a mobile screen, make the panel full screen; otherwise
// it will appear on the left side next to the nav.
const AdventurePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 25%;
  background-color: ${Colors.altBackgroundLighter};
  border-right: 1px solid ${Colors.border};
  overflow-y: auto;
  height: 100vh;

  @media (min-width: 900px) {
    max-width: 400px;
  }
  @media (max-width: 900px) {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    padding: 1em;
  }

  .close-button {
    top: 0.5em;
    right: 0.5em;
    border: none;
    z-index: 2;
    position: fixed;
    background-color: transparent;
    font-size: 2em;
  }

  .section {
    padding-left: 0.5em;
    h1 {
      font-size: 0.8em;

      font-variant: small-caps;
      color: ${Colors.lighterText};
    }
  }

  .item {
    padding-top: 0.5em;
  }
`;

const Styles = styled.div`
  // overlay at the top right corner of the enclosing div

  .panel-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px dotted ${Colors.border};
    padding-bottom: 0.5em;

    .content {
      padding: 0 0.5em;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 0.4em;

      .info {
        margin-left: 1em;
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 0.9em;
          margin: 0;

          .state-icon {
            margin-left: 0.5em;
            font-size: 0.8em;
            color: ${Colors.lighterText};
          }
        }
        h3 {
          font-size: 0.7em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          margin: 0;
        }
        @media (max-width: 600px) {
          h1 {
            font-size: 1.6em;
          }
          h3 {
            font-size: 1.2em;
          }
        }
      }

      &.character {
        img {
          border: 4px solid ${Colors.border};
          border-radius: 50%;
          width: 48px;
          height: 48px;
        }
        height: 48px;
      }

      &.lead-character {
        img {
          border-color: goldenrod;
        }
      }

      &.adventure {
        img {
          width: 48px;
          height: 48px;
        }
        h3 {
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .enemy-dialog {
    display: flex;
    flex-direction: column;
    width: 50vw;
    max-width: 600px;
    height: 50vh;
    max-height: 600px;
  }

  .adventure-panel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;

    h1 {
      margin-left: 0.5em;
      font-size: 1.3em;
    }

    @media (min-width: 900px) {
      .cover-art-container {
        position: relative;
        width: 100%;
        padding-bottom: 100%;

        .cover-art {
          position: absolute;
          width: 100%;

          img {
            position: absolute;
            max-width: 100%;
            max-height: auto;
          }

          p {
            position: absolute;
            left: 0;
            text-align: center;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            font-weight: 800;
            font-size: 24px;
            color: white;
            text-shadow: 2px 2px 4px black;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    @media (max-width: 900px) {
      .cover-art-container {
        max-width: 100%;
        margin: 1em;
        img {
          max-width: 100%;
        }
      }
    }
  }
`;
