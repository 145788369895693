import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShield, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import Rating from "react-rating";

import Colors, { Paragraphs, Subtitle } from "../shared-components";
import { useEffect, useRef, useState } from "react";
import FeedbackDialog from "./FeedbackDialog";
import CenteredModal from "./CenteredModal";
import Image from "./Image";
import { AudioPlayer } from "./AudioPlayer";

export default function Transcript({
  adventure,
  partialResponse,
  isPlayerCharacter,
}) {
  const lastId =
    adventure.transcript.messages[adventure.transcript.messages.length - 1]?.id;

  return (
    <Styles>
      <div className="introduction">
        <div className="cover-art">
          <Image id={adventure.imageId} framed />
        </div>

        <Paragraphs text={adventure.introduction} />
        {adventure.introductionAudioUrl && (
          <AudioPlayer url={adventure.introductionAudioUrl} />
        )}
      </div>
      <div className="transcript">
        <OpeningSceneItem
          content={adventure.openingScene}
          audioUrl={adventure.openingSceneAudioUrl}
        />
        {adventure.transcript.messages.map((item) => {
          const isLast = item.id === lastId;

          if (item.role === "picture") {
            return (
              <PictureTranscriptItem
                key={item.id}
                imageId={item.imageId}
                content={item.content}
              />
            );
          } else if (item.role === "gm") {
            return (
              <GMTranscriptItem
                key={item.id}
                content={item.content}
                adventureId={adventure.id}
                itemId={item.id}
                isUpdating={item.isUpdating}
                audioUrl={item.audioUrl}
                isPlayerCharacter={isPlayerCharacter}
                isLast={isLast}
              />
            );
          }

          return <PCTranscriptItem {...item} key={item.id} />;
        })}
        {partialResponse && (
          <PartialResponseTranscriptItem content={partialResponse} />
        )}
      </div>
    </Styles>
  );
}

function OpeningSceneItem({ audioUrl, content }) {
  return (
    <div className="transcript-item opening-scene">
      {audioUrl && <AudioPlayer url={audioUrl} />}
      <Paragraphs text={content} />
    </div>
  );
}

function PartialResponseTranscriptItem({ content }) {
  return (
    <div className="transcript-item gm">
      <img
        src="/images/DungeonMaster.jpg"
        alt="GM"
        className="gm avatar framed"
      />
      <Paragraphs text={content} />
    </div>
  );
}

function PictureTranscriptItem({ imageId, content }) {
  return (
    <div className="transcript-item picture">
      <Image id={imageId} framed />
      <p className="caption">{content}</p>
    </div>
  );
}

function GMTranscriptItem({
  content,
  isUpdating,
  adventureId,
  itemId,
  isPlayerCharacter,
  audioUrl,
  isLast,
}) {
  const [showLength, setShowLength] = useState(0);
  const lastItemRef = useRef(null);

  // if isUpdating, start a timer to show a substring of the content.
  useEffect(() => {
    if (isUpdating || showLength > 0) {
      const timer = setInterval(() => {
        setShowLength((length) => length + 1);
      }, 20);

      return () => clearInterval(timer);
    }
  }, [isUpdating, content.length, showLength, lastItemRef]);

  // only for the last item, scroll it into view every time its
  // content is update.
  useEffect(() => {
    if (lastItemRef.current && isLast) {
      lastItemRef.current.scrollIntoView();
    }
  }, [lastItemRef, showLength, isLast]);

  if (showLength > 0 && showLength < content.length && isLast) {
    content = content.substring(0, showLength);
  }

  return (
    <div className="transcript-item gm">
      <div className="actions">
        {audioUrl && <AudioPlayer url={audioUrl} />}
        <Feedback
          content={content}
          adventureId={adventureId}
          itemId={itemId}
          isPlayerCharacter={isPlayerCharacter}
        />
      </div>

      <img
        src="/images/DungeonMaster.jpg"
        alt="GM"
        className="gm avatar framed"
      />
      <Paragraphs text={content} />
      <div ref={lastItemRef} />
    </div>
  );
}

function PCTranscriptItem({ xp, imageId, content, speaker }) {
  return (
    <div className={`transcript-item pc`}>
      <div className="avatar pc">
        <Image id={imageId} framed />
      </div>
      <Paragraphs text={content} />
      <RatingIndicator xp={xp} />
    </div>
  );
}

function Feedback({ content, adventureId, itemId, isPlayerCharacter }) {
  const [showDialog, setShowDialog] = useState(false);

  return (
    isPlayerCharacter && (
      <>
        <div className="feedback clickable" onClick={() => setShowDialog(true)}>
          <FontAwesomeIcon icon={faThumbsDown} />
        </div>
        <FeedbackDialog
          show={showDialog}
          onClose={() => setShowDialog(false)}
          content={content}
          adventureId={adventureId}
          itemId={itemId}
        />
      </>
    )
  );
}

function RatingIndicator({ xp }) {
  const [showXpDetails, setShowXpDetails] = useState(false);
  if (!xp) return "";

  return (
    <div className="rating-container">
      {showXpDetails && (
        <XPDetails
          onClose={() => setShowXpDetails(false)}
          show={showXpDetails}
          xp={xp}
        />
      )}
      <div
        className="rating clickable"
        onClick={(e) => {
          setShowXpDetails(true);
        }}
      >
        <RatingStars rating={xp.roleplayScore} />
        <span className="xp">{xp.xpAwarded} xp</span>
      </div>
    </div>
  );
}

function XPDetails({ xp, onClose, show }) {
  console.log("Close", onClose);
  console.log("Show", show);
  const { roleplayScore, rationale, difficultyScore, xpAwarded } = xp;

  return (
    <CenteredModal onClose={onClose} show={show}>
      <div className="modal-content">
        <h1>Experience Points</h1>
        <hr />
        <Subtitle>
          <p className="subtitle">
            Unlike most other games, we reward you for your creativity and how
            you roleplay your character. The more you act in accordance with
            your character's personality, alignment and background, the more
            experience points you will earn. You will also earn experience
            points for overcoming difficult challenges and adversaries.
          </p>
        </Subtitle>
        <p>
          <strong>Roleplay Score</strong>:
          <RatingStars rating={roleplayScore} />
          {roleplayScore} / 5
          <br />
          <strong>Difficulty Score</strong>:
          <RatingStars rating={difficultyScore} />
          {difficultyScore} / 5
          <br />
          <strong>Earned</strong>: {xpAwarded} xp.
          <br />
        </p>
        <p>{rationale}</p>
      </div>
    </CenteredModal>
  );
}

function RatingStars({ rating }) {
  return (
    <Rating
      className="rating-stars"
      initialRating={rating}
      emptySymbol={
        <FontAwesomeIcon
          icon={faShield}
          style={{ color: Colors.lighterText }}
        />
      }
      fullSymbol={<FontAwesomeIcon icon={faShield} style={{ color: "gold" }} />}
      readonly
      fractions={2}
    />
  );
}

const Styles = styled.div`
  .introduction {
    position: relative;
    display: block;
    overflow: auto;
    p {
      font-style: italic;
    }

    .cover-art {
      float: right;
      width: clamp(200px, 30%, 300px);
      margin: 0 1em;
      padding: 1em 0;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .transcript-item {
    background-color: ${Colors.altBackgroundDarker};
    padding: 1em;
    min-height: 84px;
    border-top: 1px solid ${Colors.border};
    position: relative;
    overflow: hidden;

    .actions {
      position: absolute;
      padding: 0.5em;
      bottom: 0.5em;
      right: 0.5em;
      background-color: transparent;
      color: ${Colors.lighterText};
      display: none;
    }

    &:hover {
      .actions {
        display: flex;
        flex-direction: row;
        > * {
          margin-left: 1em;
          padding: 0.5em;
        }
      }
    }

    &.pc {
      background-color: ${Colors.altBackgroundLighter};
    }

    p {
      margin-top: 10px;
      padding-top: 0;
    }

    .avatar {
      width: 72px;
      height: 72px;
      margin: 10px;
    }

    img.gm {
      float: right;
    }

    .pc {
      img {
        width: 100%;
        height: auto;
      }
      float: left;
    }

    .rating-container {
      display: flex;
      flex-direction: row-reverse;

      .rating {
        .xp {
          color: ${Colors.lighterText};
        }
      }
    }

    &.picture {
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        height: auto;

        @media (max-width: 600px) {
          width: 90%;
          left: 50%;
          position: relative;
          top: 0;
          transform: translateX(-50%);
        }
      }

      p.caption {
        font-style: italic;
        text-align: center;
      }
    }
  }

  .modal-content {
    h1 {
      text-align: center;
    }

    border: 3px groove ${Colors.border};
    padding: 1em;
  }

  .rating-stars {
    padding-top: 0;
    padding: 0.2em;
    svg {
      color: ${Colors.gold};
      font-size: 0.7em;
    }
  }
`;
