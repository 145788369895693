import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Card, isMobileScreen } from "../shared-components";
import { CharacterSheet } from "./CharacterDialog";
import { AuthContext } from "../AuthContext";
import { CampaignEditor } from "./CampaignDialog";
import { characterUrl } from "./Main";

export default function CreateCampaign() {
  const { apiClient } = useContext(AuthContext);
  const characterId = useParams()["id"];
  const [character, setCharacter] = useState(null);

  useEffect(() => {
    if (!apiClient || !characterId) return;
    apiClient
      .get(`/characters/${characterId}`)
      .then(({ data }) => {
        setCharacter(data);
      })
      .catch((err) => {
        console.error(err);
        window.location.href = "/characters/new";
      });
  }, [apiClient, characterId]);

  if (!character) return "";

  function retry() {
    apiClient
      .post(`/characters/${character.id}/recreateCampaign`)
      .then(({ data }) => {
        setCharacter(data);
      });
  }

  function deleteCharacter(e) {
    apiClient
      .delete(`/players/${character.playerId}/characters/${character.id}`)
      .then(() => {
        window.location.href = "/characters/new";
      });
  }

  console.log("CreateCampaign", character);
  return (
    <CreatingCharacterStyles>
      <div className="creating-character">
        <div className="char-sheet">
          <Card>
            <CharacterSheet className="char-sheet" character={character} />
          </Card>
        </div>
        <div className="status">
          <CampaignEditor
            campaignId={character.campaignId}
            onSubmit={() => {
              window.location = characterUrl(character.id);
            }}
            onRetry={retry}
            onDelete={deleteCharacter}
          />
        </div>
      </div>
    </CreatingCharacterStyles>
  );
}

const CreatingCharacterStyles = styled.div`
  ${!isMobileScreen() &&
  `
  max-width: 1200px;
  margin: auto;
  .creating-character {
    display: flex;
    flex-direction: row;
    max-height: 100vh;

    .char-sheet {
      width: 50%;
      max-width: 50%;
      overflow-y: auto;
    }
    .status {
      width: 50%;
      max-width: 50%;
      overflow-y: auto;
    }
  }`}
`;
