import { useContext, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { characterUrl } from "./Main";
import { AuthContext } from "../AuthContext";

// the campaign component simply redirects to the main character page
export default function Campaign() {
  const { apiClient } = useContext(AuthContext);
  const { id } = useParams();

  const [campaign, setCampaign] = useState(null);
  useEffect(() => {
    if (!apiClient || !id) return;
    apiClient
      .get(`/campaigns/${id}`)
      .then(({ data }) => {
        setCampaign(data);
      })
      .catch((err) => {
        console.error(err);
      });
  });

  if (!campaign) return "";
  const url = characterUrl(campaign.mainCharacterId);

  return <Navigate url={url} />;
}
