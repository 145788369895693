import styled from "styled-components";
import { Themes } from "./themes.ts";

export let Colors = Themes.light;
if (
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
) {
  Colors = Themes.dark;
}

export default Colors;
export const Link = function ({ href, children }) {
  if (href) {
    return <a href={href}>{children}</a>;
  } else {
    return <>{children}</>;
  }
};

// set the window's background color to match the body's background color
// so that the window's background color is visible when the body is scrolled
window.document.body.style.backgroundColor = Colors.background;

export function isMobileScreen() {
  return window.innerWidth < 600;
}

export function isTabletScreen() {
  return window.innerWidth < 900;
}

export const Body = styled.div`
  font-size: 22px;
  @media (max-width: 600px) {
    font-size: 18px;
  }

  background-color: ${Colors.background};
  color: ${Colors.color};
  font-family: "EB Garamond";
  margin: 0;
  padding: 0;

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }

  h1 {
    font-size: 1.6em;
  }
  h2 {
    font-size: 1.4em;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1.2em;
  }
  h3 {
    font-size: 1.1em;
  }

  a {
    color: unset;
  }
  a:visited {
    color: unset;
  }

  a:hover {
    text-decoration: underline;
    background-color: ${Colors.altBackgroundLighter};
  }

  p {
    margin: 1em 0.5em;
    font-size: 1.2em;
  }

  ul {
    list-style-type: none;
    padding-left: 0.2em;
  }

  table {
    thead tr th {
      border-bottom: 1px solid ${Colors.border};
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }
    tbody tr td {
      padding: 0.25em;
      margin-top: 0.5em;
    }

    td.right {
      text-align: right;
      padding-right: 0.5em;
    }
  }

  th {
    text-align: left;
  }

  button,
  a.button {
    border: 1px solid ${Colors.border};
    white-space: nowrap;
    padding: 0.3em;
    margin-left: 0.5em;
    // background is darkened with some transparency
    background-color: rgba(128, 128, 128, 0.6);
    border: 4x solid;
    border-color: ${Colors.border};
    color: ${Colors.color};
    border-radius: 0.2em;
    font-size: 1em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    cursor: pointer;

    &.primary {
      background-color: ${Colors.primaryBackground};
      border-color: ${Colors.primaryBorder};
    }

    &.danger {
      background-color: ${Colors.deleteBackground};
      border-color: ${Colors.deleteBorder};
      color: ${Colors.darkerText};
    }
    &.warning {
      border-color: ${Colors.deleteBorder};
      color: ${Colors.darkerText};
    }

    &:disabled {
      background-color: ${Colors.altBackgroundLighter};
      color: ${Colors.lighterText};
    }
  }

  a.button {
    text-decoration: none;
  }

  img.framed {
    border: 3px groove ${Colors.border};
  }

  .clickable {
    cursor: pointer;

    &:hover {
      // make the color a bit darker translucent
      background-color: rgba(255, 255, 255, 0.3);
      outline: 1px solid ${Colors.border};
    }
  }
`;

export const Content = styled.div`
  display: flex;

  font-size: 0.8em;
  overflow-y: auto;
  padding: 1em;
`;

export const Header = styled.div`
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 1em;
  background-color: ${Colors.altBackgroundDarker};
  border-bottom: 2px solid ${Colors.border};

  @media (max-width: 600px) {
    left: 0;
    z-index: 1;
  }

  h1 {
    font-size: 24px;
    flex-grow: 1;
    padding: 0;
  }
  h2 {
    font-size: 18px;
    padding: 0;
    font-style: italic;
  }

  select {
    margin-left: 1em;
    padding: 0.5em;
    font-size: 1em;
  }

  input[type="text"],
  input[type="search"] {
    margin-left: 1em;
    padding: 0.5em;
    width: 20em;
    font-size: 1em;
  }
`;

export const Footer = styled.div`
  padding: 0.5em;
  background-color: ${Colors.altBackgroundLighter};
  border-top: 1px solid ${Colors.border};
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const CloseButtonContainer = styled.div`
  position: relative;
  padding: 0;
  margin: 0;

  :hover {
    button {
      display: block;
    }
  }

  button {
    position: absolute;
    font-size: 14px;
    top: 0px;
    right: 0px;
    padding: 8px;
    border: 1px solid ${Colors.border};
    border-radius: 3px;
    cursor: pointer;
    display: none;
  }

  button:disabled {
    cursor: default;
  }
`;

export const Actions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  overflow-x: wrap;
  margin-right: 12px;
  align-items: center;

  // when there's not enough space, wrap and add a bit of vertical margin
  flex-wrap: wrap;

  a {
    margin-left: 12px;
  }
`;

export const Card = styled.div`
  background-color: ${Colors.altBackgroundLighter};
  margin: 1rem;
  padding: 1.5rem;
  border: 1px dotted ${Colors.border};
  box-shadow: 2px 2px 0 ${Colors.border};
  border-radius: 4px;
`;

export const Subtitle = styled.div`
  color: ${Colors.lighterText};
  font-style: italic;
`;

export function Paragraphs({ text }) {
  if (!text) return "";

  const paragraphs = text.split("\n");
  return (
    <>
      {paragraphs.map((paragraph, i) => (
        <p key={i}>{paragraph}</p>
      ))}
    </>
  );
}

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: ${Colors.contrastBackground};
  color: ${Colors.contrastColor};
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s;

  /* Anchor points */
  &.top {
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);

    // make a pointer on the top side
    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: ${Colors.contrastBackground} transparent transparent
        transparent;
    }
  }
  &.left {
    top: 50%;
    right: calc(100% + 5px);
    transform: translateY(-50%);

    // make a pointer on the right side
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent transparent
        ${Colors.contrastBackground};
    }
  }

  &.bottom {
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);

    // make a pointer on the bottom side
    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent ${Colors.contrastBackground}
        transparent;
    }
  }

  &.right {
    top: 50%;
    left: calc(100% + 15px);
    transform: translateY(-50%);
    // make a pointer on the left side
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent ${Colors.contrastBackground} transparent
        transparent;
    }
  }
`;

export const Tooltip = ({ text, children, anchor }) => {
  anchor = anchor || "top";
  return (
    <TooltipContainer data-tooltip={text}>
      {children}
      <TooltipText className={`tooltipText ${anchor}`}>{text}</TooltipText>
    </TooltipContainer>
  );
};
