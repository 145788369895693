import React, { useState, useEffect } from "react";
import Colors, { isMobileScreen } from "../shared-components";

export default function ScrollingTextOverlay({
  imageUrl,
  text,
  size,
  onClick,
}) {
  if (isMobileScreen()) {
    size = window.innerWidth * 0.8;
  }
  size = size || 800;
  const sizePixels = `${size}px`;
  const [scrollPosition, setScrollPosition] = useState(size);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setScrollPosition((scrollPosition) => scrollPosition - 1);
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setScrollPosition(size);
  }, [imageUrl, size, text]);

  const containerStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: sizePixels,
    width: sizePixels,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    border: `3px ${Colors.border} groove`,
  };

  const textContainerStyle = {
    position: "absolute",
    top: `${scrollPosition}px`,
    width: "90%",
    textAlign: "center",
    color: "#fff",
    fontSize: "2rem",
    fontWeight: "bold",
    textShadow: "2px 2px 4px #000000",
  };

  return (
    <div style={containerStyle} onClick={onClick}>
      <div style={textContainerStyle}>{text}</div>
    </div>
  );
}
