import { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import YAML from "yaml";

import { AuthContext } from "../AuthContext";
import { Actions, Card, Content, Footer, Header } from "../shared-components";
import ThinkingDice from "./ThinkingDice";
import { CharacterSheet } from "./CharacterDialog";
import TextEditor from "./TextEditor";

export default function GameSystemBuilder() {
  const { handle } = useParams();
  const { apiClient, userId } = useContext(AuthContext);

  const [text, setText] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("OK");
  const [gameSystem, setGameSystem] = useState(null);
  const [doReload, setDoReload] = useState(false);

  useEffect(() => {
    if (!handle || !apiClient) return;
    apiClient
      .get(`/gamesystems/${handle}/source`, {
        responseType: "text",
      })
      .then(({ data }) => {
        console.log("Got game system text", data);
        setText(data);
      });
  }, [handle, apiClient, doReload]);

  useEffect(() => {
    if (!text) return;
    try {
      const gameSystem = YAML.parse(text);
      setGameSystem(gameSystem);
      const error = validateGameSystem(gameSystem);
      if (error) {
        setError(error);
      } else {
        setError(null);
        setMessage("OK");
      }
    } catch (e) {
      setGameSystem({ name: "Error parsing YAML" });
      setError(e.message);
    }
  }, [text]);

  function restore() {
    setDoReload(true);
  }

  function deleteGameSystem() {
    if (!gameSystem) return;
    const confirmed = window.confirm(
      `Are you sure you want to delete ${gameSystem.name}?`
    );
    if (!confirmed) return;
    apiClient
      .delete(`/players/${userId}/gamesystems/${gameSystem.handle}`)
      .then(() => {
        window.location = "/gamesystems/new";
      })
      .catch((e) => {
        setError(e.message);
      });
  }

  if (!text || !gameSystem) return "";

  function saveGameSystem() {
    if (error) return;
    const body = {
      gameSystemYaml: text,
    };
    apiClient
      .post("/gamesystems", body)
      .then(() => {
        setMessage(`Saved: ${gameSystem.name} (${gameSystem.handle})`);
        setError(null);
      })
      .catch((e) => {
        setError(e.message);
      });
  }

  return (
    <Styles>
      <Header>
        <h1>Game System Editor: {gameSystem.name}</h1>
        <Actions>
          <button className="danger" onClick={deleteGameSystem}>
            Delete
          </button>
          <button onClick={restore}>Restore</button>
          <button className="primary" onClick={saveGameSystem}>
            Save
          </button>
        </Actions>
      </Header>
      <Content className="content">
        <Card className="editor-container">
          <h1>Game System (YAML)</h1>
          <TextEditor text={text} onChange={setText} mode="yaml" />
        </Card>
        <div className="preview">
          <Preview
            gameSystemYaml={text}
            gameSystem={gameSystem}
            template={gameSystem.characterSheetTemplate}
            error={error}
            setError={setError}
          />
        </div>
      </Content>
      <Footer className="footer">
        {error && <div className="error">{error}</div>}
        {!error && <div className="success">{message}</div>}
      </Footer>
    </Styles>
  );
}

function Preview({ gameSystem, gameSystemYaml, error, setError }) {
  const [character, setCharacter] = useState(null);
  const [creating, setCreating] = useState(false);
  const inputRef = useRef();
  const { apiClient, userId } = useContext(AuthContext);
  const template = gameSystem?.characterSheetTemplate;

  useEffect(() => {
    if (!gameSystem || error || !apiClient || character) return;
    apiClient.get(`/characters`).then(({ data }) => {
      console.log("Got characters", data);
      data.forEach((character) => {
        if (
          character.gameSystemHandle === gameSystem.handle &&
          character.campaignId === null
        ) {
          setCharacter(character);
        }
      });
    });
  }, [gameSystem, error, apiClient, userId, character]);

  function createTestCharacter() {
    if (!gameSystemYaml || error || !apiClient) return;
    const prompt = inputRef.current?.value;
    const body = {
      gameSystemYaml,
      level: 1,
      prompt,
      previousCharacterId: character?.id,
    };
    setCreating(true);
    apiClient
      .post("/gamesystems/create-test-character", body)
      .then(({ data }) => {
        setCharacter(data);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setCreating(false);
      });
  }

  if (!gameSystemYaml || error) return "";
  if (creating) return <ThinkingDice title="Creating New Test Character" />;
  const buttonTitle = character
    ? "Create Another Character"
    : "Create Test Character";
  return (
    <Card>
      <div>
        <h1>Test Character</h1>
        {character && (
          <p>
            Continue to edit the yaml, to reformat your character sheet template
            markdown. If you change the character sheet itself, you can you can
            create another character.
          </p>
        )}
        {!character && (
          <p>
            Edit your yaml, focusing in particular on the charcter sheet. When
            you're ready, create a test character.
          </p>
        )}
        <form onSubmit={createTestCharacter}>
          <input ref={inputRef} placeholder="New character prompt (optional)" />
          <Actions>
            <button onClick={createTestCharacter}>{buttonTitle}</button>
          </Actions>
        </form>
        {template && character && (
          <CharacterSheet character={character} template={template} />
        )}
        {!template && "No Template"}
      </div>
    </Card>
  );
}

const Styles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
  }

  .content {
    flex-grow: 1;
    display: flex;

    .editor-container {
      width: 60%;
      overflow-y: scroll;
      .editor {
      }
    }
    .preview {
      width: 40%;
      form {
        font-size: 14px;
        input {
          font-size: 16px;
          padding: 0.5em;
          margin: 0.5em;
        }
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
      overflow-y: scroll;
    }
  }

  .footer {
    font-family: monospace;
    font-size: 14px;
    .error {
      color: red;
    }
  }
`;

function validateGameSystem(gameSystem) {
  try {
    function requireKey(key, description, type = "string") {
      if (!gameSystem[key]) {
        return `Required: ${key} (${type}): ${description}`;
      }
      return null;
    }

    return (
      requireKey("handle", "Unique identifier for this game system") ||
      requireKey("name", "Name to be displayed for this game system") ||
      requireKey(
        "description",
        "Brief description of the game system to be used in UI and to guide ChatGPT"
      ) ||
      requireKey(
        "systemPrompt",
        "Incorporated in virtually every ChatGPT prompt to help it understand the nature of the game."
      ) ||
      requireKey(
        "characterSummary",
        "Format for summarizing a character using the character sheet",
        "handlebars"
      ) ||
      requireKey(
        "portraitPrompt",
        "Prompt to generate character portrait using the character sheet",
        "handlebars"
      ) ||
      requireKey(
        "picturePrompt",
        "Prompt to generate pictures for adventure transcript"
      ) ||
      requireKey(
        "characterSheet",
        "Character Sheet Definition. In many ways, the character sheet is the key thing that defines the game system you want to play.",
        "object"
      ) ||
      requireKey(
        "characterSheetTemplate",
        "Character Sheet Display Template",
        "Markdown / Handlebars"
      )
    );
  } catch (e) {
    return e.message;
  }
}
