import { useRef } from "react";
import styled from "styled-components";
import Colors, { isMobileScreen } from "../shared-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// this component appears on the right side of the screen, showing drill down
// information for a selected item such as a character. It is a modal dialog.
export default function OverlayDialog({ children, onClose, show, width }) {
  const overlayRef = useRef(null);
  if (!show || !children) return "";
  if (isMobileScreen()) {
    width = "100%";
  } else {
    width = width || "400px";
  }

  return (
    <Styles width={width}>
      <div
        className="overlay"
        ref={overlayRef}
        onClick={(e) => {
          // only close when clicking on the background overlay; ignore
          // clicks on children
          if (e.target === overlayRef.current) {
            e.preventDefault();
            onClose();
          }
        }}
      >
        {isMobileScreen() && (
          <button className="close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        )}
        <div className="panel" onClick={() => {}}>
          <div className="content">{children}</div>
        </div>
      </div>
    </Styles>
  );
}

const Styles = styled.div`
  .close-button {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    border: none;
    z-index: 103;
    background-color: transparent;
    position: fixed;
    font-size: 2em;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;

    .panel {
      position: fixed;
      right: 0;
      top: 0;
      width: ${(props) => props.width};
      height: 100vh;
      background-color: ${Colors.altBackgroundDarker};
      box-shadow: 0 0 -10px rgba(0, 0, 0, 0.5);
      z-index: 100;

      .content {
        overflow-y: auto;
        height: 100%;
      }
    }
  }
`;
