import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { AuthContext, setUnauthenticatedPlayerId } from "../AuthContext";
import { isMobileScreen } from "../shared-components";
import CharacterForm from "./CharacterForm";
import ThinkingDice from "./ThinkingDice";
import GameSystemCard from "./GameSystemCard";
import StaticMarkdown from "./StaticMarkdown";
import UpgradePlan from "./UpgradePlan";
import { PLANS } from "./Nav";
import { useNavigate } from "react-router-dom";

export default function CreateCharacter() {
  const [gameSystems, setGameSystems] = useState(null);
  const [gameSystemHandle, setGamesystemHandle] = useState(null);
  const [rollingCharacter, setRollingCharacter] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();

  const { apiClient, userId, unauthenticated } = useContext(AuthContext);
  useEffect(() => {
    if (!apiClient) return;
    apiClient.get("/gamesystems").then(({ data }) => {
      setGameSystems(data);
      setGamesystemHandle(data[0].handle);
    });
    apiClient.get(`/me`).then(({ data }) => {
      setUserInfo(data);
    });
  }, [apiClient]);

  function createCharacter(level, prompt) {
    setRollingCharacter(true);
    const body = { level, prompt, gameSystemHandle: gameSystem.handle };
    const url = `/characters/create`;
    console.log("Create Character", url);
    apiClient
      .post(url, body)
      .then(({ data }) => {
        const character = data;
        const path = unauthenticated ? "/signup-now" : "/create-campaign";
        setUnauthenticatedPlayerId(character.playerId);
        navigate(`/characters/${character.id}${path}`);
      })
      .catch((e) => {
        console.log("Error", e);
        // FIXME
        setRollingCharacter(false);
      });
  }

  if (!userInfo) return "";
  if (!gameSystems || !gameSystemHandle) return "";
  const gameSystem = gameSystems.find((g) => g.handle === gameSystemHandle);
  if (userInfo.plan === PLANS.Free) {
    return <UpgradePlan {...userInfo} />;
  }

  const title = userId ? "Create a new character" : "Rolecraft (Alpha)";
  const subTitle =
    userId && !unauthenticated
      ? ""
      : "Let ChatGPT be your Dungeon Master. Play for free.";
  const pickGameSystem =
    userId && !isMobileScreen() && !unauthenticated ? true : false;

  if (rollingCharacter) {
    return (
      <Styles>
        <div className="creating-character">
          <h2>Creating your character</h2>
          <p>This will take a few seconds...</p>
          <ThinkingDice title={prompt} noPicture />
          <hr />
          <div className="about">
            <div className="gm">
              <img
                src="/images/DungeonMaster.jpg"
                alt="Game Master"
                className="framed"
              />
              <div className="caption">I am your AI-Based Game Master</div>
            </div>
            <StaticMarkdown file="/about.md" />
          </div>
        </div>
      </Styles>
    );
  }

  return (
    <Styles>
      <div className="create-character">
        <h1>{title}</h1>
        {subTitle && <h3>{subTitle}</h3>}
        {pickGameSystem && (
          <div className="gamesystems">
            <hr />
            <h2>Choose a Game System</h2>
            <h3>More game systems coming soon.</h3>
            {gameSystems.map((gameSystem) => (
              <GameSystemCard
                {...gameSystem}
                onClick={() => setGamesystemHandle(gameSystem.handle)}
                key={gameSystem.handle}
                selected={gameSystemHandle === gameSystem.handle}
              />
            ))}
          </div>
        )}

        <hr />
        <div className="character-form">
          <h2>Tell me a bit about your character</h2>
          <h3>Here's an example.</h3>
          <CharacterForm
            gameSystem={gameSystem}
            createCharacter={createCharacter}
          />
        </div>
      </div>
    </Styles>
  );
}

const Styles = styled.div`
  background-image: url("/images/join-campaign.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  color: white;
  height: 100vh;
  margin: 0;
  padding: 2em;

  display: flex;
  align-items: center;
  text-shadow: 2px 2px 4px #000000;

  @media (min-width: 600px) {
    justify-content: center;
  }

  .create-character {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: clamp(300px, 80vw, 800px);

    h2 {
      /* margin-bottom: 0.2em; */
    }
    h3 {
      font-style: italic;
      margin-top: 0;
    }

    hr {
      width: 100%;
    }

    .gamesystems {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 1em;
    }

    button {
      color: white;
    }
  }

  .creating-character {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    @media (max-width: 600px) {
      margin-left: 2em;
      margin-right: 2em;

      width: 90vw;
      padding: 0 1em;
    }

    .about {
      display: block;
      .gm {
        width: clamp(20%, 40vw, 40%);
        @media (max-width: 600px) {
          width: 30%;
        }
        display: flex;
        flex-direction: column;
        float: right;
        margin: 0 0 1em 1em;
        .caption {
          margin-top: 0.5em;
          font-style: italic;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
`;
