/* eslint-disable jsx-a11y/anchor-is-valid */
import { AuthContext } from "../AuthContext.js";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CodeMirror from "@uiw/react-codemirror";
import Markdown from "react-remarkable";

import YAML from "yaml";

import ThinkingDice from "../components/ThinkingDice.js";
import { Header, Actions, Colors, Footer } from "../shared-components.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

export default function ChatGptLog({ id, onNext, onPrevious, index, onClose }) {
  const [item, setItem] = useState(null);
  const [messages, setMessages] = useState(null);
  const [testResult, setTestResult] = useState(null);
  const [runningTest, setRunningTest] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [text, setText] = useState(null);

  const { apiClient } = useContext(AuthContext);

  useEffect(() => {
    apiClient.get(`/admin/chatGptLogs/${id}`).then(({ data }) => {
      const removeDoubleNewLines = data.messages.map((m) => {
        return {
          role: m.role,
          content: m.content.replace(/\n/g, `\\n`),
          // content: m.content,
        };
      });
      console.log("id", id);
      console.log("data", data);
      setItem(data);
      setMessages(data.messages);
      setText(YAML.stringify(removeDoubleNewLines));
      setTestResult(null);
      setErrorMessage(null);
    });
  }, [id, apiClient, index]);

  function runTest() {
    setRunningTest(true);
    apiClient
      .post("/admin/chatGptLogs/test", {
        messages,
      })
      .then(({ data }) => {
        console.log(data);
        setTestResult(data);
      })
      .finally(() => {
        setRunningTest(false);
      });
  }

  function appendCompletion() {
    const newMessage = {
      role: "assistant",
      content: item.completionText,
    };
    setMessages([...messages, newMessage]);
    setText(YAML.stringify([...messages, newMessage]));
  }

  if (!item) return <ThinkingDice fullScreen />;
  return (
    <Styles>
      <Header>
        <h1>Chat Logs</h1>
        <Actions>
          <button onClick={onPrevious}>&lt;</button>
          <button onClick={onNext}>&gt;</button>
          <button onClick={onClose}>Close</button>
        </Actions>
      </Header>
      <div className="content">
        <div className="prompt-container">
          <div className="prompt">
            <h2>Prompt</h2>
            <MessagesEditor
              text={text}
              messages={messages}
              onChange={(text, messages) => {
                setMessages(messages);
                setText(text);
              }}
              setErrorMessage={setErrorMessage}
            />
          </div>
        </div>
        <div className="response-container">
          <Results
            item={item}
            testResult={testResult}
            runningTest={runningTest}
          />
        </div>
      </div>
      <Footer>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Actions>
          <button onClick={appendCompletion}>Append Completion</button>
          <button onClick={runTest}>Test Changes</button>
        </Actions>
      </Footer>
    </Styles>
  );
}

function Results({ item, testResult, runningTest }) {
  const prompt = [...item.messages].slice(-1)[0].content;

  return (
    <div className="response">
      <h2>Completion</h2>
      <p>{prompt}</p>
      <Completion title="Original" {...item} />
      {runningTest && <ThinkingDice />}
      {!runningTest && testResult && (
        <Completion title="TestResult" {...testResult} />
      )}
    </div>
  );
}

function Completion({
  title,
  completionText,
  promptTokens,
  completionTokens,
  totalTokens,
}) {
  const [collapsed, setCollapsed] = useState(false);

  try {
    JSON.parse(completionText);
    completionText = "```json\n" + completionText + "\n```";
  } catch (e) {
    // swallow
  }

  const icon = collapsed ? faPlusSquare : faMinusSquare;
  return (
    <div className="completion">
      <h2>
        <a onClick={() => setCollapsed(!collapsed)} href="#">
          <FontAwesomeIcon icon={icon} style={{ marginRight: "0.5em" }} />
          {title}&nbsp;&nbsp;
        </a>
      </h2>
      {!collapsed && <Markdown source={completionText} />}
      <div className="usage">
        <span>Prompt: {promptTokens} / </span>
        <span>Completion: {completionTokens} / </span>
        <span>Total: {totalTokens}</span>
      </div>
    </div>
  );
}

function MessagesEditor({ messages, onChange, setErrorMessage }) {
  return (
    <div className="messages-editor">
      {messages.map((m, index) => {
        return (
          <MessageEditor
            key={index}
            role={m.role}
            content={m.content}
            onChange={(newMessage) => {
              const newMessages = [...messages];
              newMessages[index] = newMessage;
              onChange(YAML.stringify(newMessages), newMessages);
            }}
          />
        );
      })}
    </div>
  );
}

function MessageEditor({ role, content, onChange }) {
  return (
    <div className="message-editor">
      <div className="role">
        <select
          value={role}
          onChange={(e) => onChange({ role: e.target.value, content })}
        >
          <option value="user">User</option>
          <option value="system">System</option>
          <option value="assistant">Assistant</option>
        </select>
      </div>
      <div className="content">
        <textarea
          value={content}
          onChange={(e) => onChange({ role, content: e.target.value })}
        />
      </div>
    </div>
  );
}

// keeping this around since I'm not yet sure which editor i prefer
// eslint-disable-next-line
function YamlMessagesEditor({ text, onChange, setErrorMessage }) {
  function onYamlChange(yaml) {
    try {
      const messages = YAML.parse(yaml);
      messages.forEach(({ role, content }, index) => {
        if (!role) throw new Error(`Missing role (message ${index})`);
        if (!content) throw new Error(`Missing content (message ${index})`);

        if (["user", "system", "assistant"].indexOf(role) === -1) {
          throw new Error(`Invalid role: ${role};`);
        }
        if (!content) {
          throw new Error(`Missing content for role: ${role}`);
        }
      });
      console.log("Change", messages);
      setErrorMessage(null);
      onChange(yaml, messages);
    } catch (e) {
      setErrorMessage(e.message);
    }
  }

  const prefersDarkMode = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const codeMirrorOptions = {
    basicSetup: {
      lineNumbers: false,
      lineWrapping: true,
      foldGutter: true,
    },
    theme: prefersDarkMode ? "dark" : "light",
    style: {
      background: Colors.altBackgroundDarker,
      fontSize: "14px",
    },
    mode: "yaml",
  };

  return (
    <div className="messages-editor">
      <CodeMirror value={text} onChange={onYamlChange} {...codeMirrorOptions} />
    </div>
  );
}

const Styles = styled.div`
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  h1 {
    font-size: 1.2em;
  }
  .content {
    flex: 1;
    width: 100%;
    h2 {
      font-size: 18px;
    }
    margin-top: 12px;
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
    overflow-y: auto;

    .prompt-container {
      margin: 1em;
      margin-right: 0;
      width: 60%;

      .prompt {
        h2 {
          padding-left: 10px;
        }
        padding: 1em;

        border: 1px solid ${Colors.border};
        background: ${Colors.altBackgroundLighter};
        display: flex;
        flex-direction: column;
      }
    }
    .response-container {
      width: 40%;
      overflow-y: auto;
      margin: 1em;
      .response {
        font-size: small;
        background-color: ${Colors.altBackgroundLighter};
        padding: 1em;

        border: 1px solid ${Colors.border};

        p {
          font-family: sans-serif;
          margin-bottom: 1em;
        }

        .completion {
          .usage {
            text-align: end;
            font-style: italic;
          }

          pre,
          code {
            white-space: break-spaces;
          }
        }
      }
    }

    .messages-editor {
      display: flex;
      flex-direction: column;
      flex: 1;
      .message-editor {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        flex: 1;
        .role {
          width: 100px;
          select {
            width: 100%;
          }
          margin-right: 1em;
        }
        .content {
          flex: 1;
          textarea {
            width: 100%;
            height: 100px;
            resize: vertical;
          }
        }
      }
    }
  }
`;

const ErrorMessage = styled.div`
  font-family: monospace;
  color: red;
  margin-left: 5rem;
`;
