import styled from "styled-components";
import { Body } from "../shared-components";

export default function NotFound() {
  return (
    <Body>
      <Styles>
        <h1>Not Found</h1>
        <img alt="404" className="framed" src="/images/not-found.jpg" />
      </Styles>
    </Body>
  );
}

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  img {
    width: clamp(20em, 50vw, 50em);
    height: auto;
  }
`;
