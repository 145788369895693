import axios from "axios";

import { useState, useEffect, createContext } from "react";
import { createClient } from "@supabase/supabase-js";
import Login from "./components/Login";

export const supabase = createClient(
  "https://nbphlzmrephbzddckuxq.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5icGhsem1yZXBoYnpkZGNrdXhxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODQwNjY2NzMsImV4cCI6MTk5OTY0MjY3M30.sxXrEspdRcv6A886bi9SJotWSXl14DQVtA5ypDwtvbM"
);
export const AuthContext = createContext();

export function isLocalDev() {
  return (
    window.location.hostname === "localhost" && window.location.port === "3000"
  );
}

export function isLocalTest() {
  return (
    window.location.hostname === "localhost" && window.location.port === "8070"
  );
}

export function setUnauthenticatedPlayerId(playerId) {
  if (playerId === "null") {
    playerId = null;
  }
  localStorage.setItem("playerId", playerId);
  localStorage.setItem("hasVisited", true);
}

export function getUnauthenticatedPlayerId() {
  const result = localStorage.getItem("playerId");
  localStorage.setItem("hasVisited", true);
  if (result === "null") {
    return null;
  }

  return result;
}

// return true if this is not the first time this client has visited
export function hasVisited() {
  return localStorage.getItem("hasVisited") === "true";
}

// optionally disable for local offline dev
const baseURL = isLocalDev() ? "http://localhost:8080/api" : "/api";

export default function WithAuth({ children }) {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [testUser, setTestUser] = useState(null);
  const unauthPlayerId = getUnauthenticatedPlayerId();

  function isPublicUrl() {
    const pathName = window.location.pathname;

    // e.g. "/lorelei-8NDKoQ"
    const characterUrlPath = /^\/[^/]*-[a-zA-Z0-9]{6}$/;

    return (
      pathName.match(characterUrlPath) ||
      pathName.startsWith("/characters/") ||
      pathName.startsWith("/help") ||
      pathName.startsWith("/try")
    );
  }

  useEffect(() => {
    const unauthPlayerId = getUnauthenticatedPlayerId();
    supabase.auth.getSession().then(({ data: { session } }) => {
      setLoading(false);
      setSession(session);
      setUnauthenticatedPlayerId(unauthPlayerId);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setLoading(false);
      setSession(session);
      setUnauthenticatedPlayerId(unauthPlayerId);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {}, [unauthPlayerId]);

  // only for running browser tests on localhost, get a test user
  // from the server
  useEffect(() => {
    if (!isLocalTest()) return;
    fetch("/api/getTestUser")
      .then((res) => {
        res.json().then((data) => {
          const { isTestServer, user } = data;
          if (isTestServer && user) {
            setTestUser(user);
          }
        });
      })
      .catch((err) => {
        // ignore
      });
  }, []);

  if (loading) return "";
  if (testUser) {
    const apiClient = axios.create({
      baseURL,
      headers: {
        "X-Test-User-Id": testUser.id,
      },
    });
    const value = { userId: testUser.id, apiClient, user: testUser };
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }

  let userId = null;
  let apiClient = null;
  let user = null;
  let unauthenticated = true;
  if (session && session.access_token) {
    user = session.user;
    unauthenticated = false;
    apiClient = axios.create({
      baseURL,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    });
    if (session.error || !user) {
      return <div>error</div>;
    }
    userId = user.user_metadata.provider_id;
  } else if (unauthPlayerId) {
    // in this case, the user is not logged in, so we use an unauthenticated
    // temp user id
    userId = unauthPlayerId;
    const headers = userId ? { "x-unauthenticated-player-id": userId } : {};

    apiClient = axios.create({
      baseURL,
      headers,
    });
  } else if (isPublicUrl()) {
    apiClient = axios.create({
      baseURL,
    });
  }

  if (apiClient) {
    if (window.newrelic) {
      if (unauthenticated) {
        // browser agent doesn't allow booleans
        window.newrelic.setCustomAttribute("unauthenticated", "true");
      }
      if (userId) {
        window.newrelic.setCustomAttribute("userId", userId);
        window.newrelic.setCustomAttribute("player_id", userId);
      }
    } else {
      console.debug("no newrelic");
    }
    const value = { userId, session, apiClient, user, unauthenticated };
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  } else {
    return <Login />;
  }
}
