/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../AuthContext";
import Colors, { Header, Card, Footer, Actions } from "../shared-components";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TextEditor from "../components/TextEditor";

export default function KeySpace() {
  const { apiClient } = useContext(AuthContext);
  const [ids, setIds] = useState(null);
  const [selected, setSelected] = useState(null);
  const [value, setValue] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [keySpaces, setKeySpaces] = useState(null);
  const [keySpace, setKeySpace] = useState(useParams().keySpace);
  const [error, setError] = useState(null);
  const [reloadAt, setReloadAt] = useState(null);
  const searchRef = useRef(null);

  useEffect(() => {
    if (!apiClient) return;
    apiClient.get(`/admin/kv`).then(({ data }) => {
      setKeySpaces(data);
      if (!keySpace) setKeySpace(data[0]);
    });
  }, [apiClient, keySpace]);

  useEffect(() => {
    if (!apiClient || !keySpace) return;

    apiClient
      .get(`/admin/kv/${keySpace}`)
      .then(({ data }) => {
        setIds(data);

        // if the selected id is not in the list, select the first one
        if ((!selected || !data.includes(selected)) && data.length > 0) {
          setSelected(data[0]);
        }

        setError(null);
      })
      .catch((err) => {
        setError(err);
      });
  }, [apiClient, keySpace, reloadAt, selected]);

  useEffect(() => {
    if (!apiClient || !keySpace || !selected) return;
    apiClient
      .get(`/admin/kv/${keySpace}/${selected}`)
      .then(({ data }) => {
        setValue(JSON.stringify(data, null, 2));
        setError(null);
      })
      .catch((err) => {
        setError(err);
      });
  }, [apiClient, keySpace, selected]);

  function refresh() {
    setSelected(null);
    setIds(null);
    setValue(null);
    setReloadAt(Date.now());
  }

  function searchId(e) {
    e.preventDefault();
    const id = searchRef.current.value;

    apiClient
      .get(`/admin/kv/idSearch?id=${id}`)
      .then(({ data }) => {
        const { key, value } = data;
        setKeySpace(key[0]);
        setSelected(key[1]);
        setValue(JSON.stringify(value, null, 2));
      })
      .catch((err) => {
        if (error.status === 404) {
          console.log("Not Found");
        }
      });
  }

  function onSave() {
    try {
      const valueObject = JSON.parse(value);
      apiClient
        .put(`/admin/kv/${keySpace}/${selected}`, valueObject)
        .then(() => {
          toast.success("Saved Changes.", {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
          });
          setError(null);
          setHasChanged(false);
        })
        .catch((err) => {
          toast.error(err.message, {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
          });
          setError(err);
        });
    } catch (e) {
      setError(e.message);
    }
  }

  function onChange(text) {
    try {
      JSON.parse(text);
      setValue(text);
      setHasChanged(true);
      setError(null);
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  if (!ids || !keySpaces || !ids) return <div />;
  return (
    <Styles>
      <Header>
        <h1>KV Database</h1>
        <Actions>
          <form className="header-form" onSubmit={searchId}>
            <input ref={searchRef} type="text" placeholder="ID Search" />
          </form>
        </Actions>
      </Header>
      <div className="keyspaces">
        {keySpaces.map((ks) => (
          <a
            key={ks}
            className={ks === keySpace ? "selected" : ""}
            href={`/admin/kv/${ks}`}
          >
            {ks}
          </a>
        ))}
      </div>
      <div className="content">
        {ids.length === 0 && (
          <div className="empty-keyspace">
            <h2>Empty Keyspace</h2>
            <p>There are no objects in {keySpace}</p>
          </div>
        )}
        {ids.length > 0 && (
          <div className="content">
            <Card className="keys">
              <h2>Keys</h2>
              <ul>
                {ids.map((id) => (
                  <li key={id} className={id === selected && "selected"}>
                    <a href="#" onClick={() => setSelected(id)}>
                      {id}
                    </a>
                  </li>
                ))}
              </ul>
            </Card>
            {selected && value && (
              <Card className="selected">
                <h2>
                  {keySpace} / {selected}
                </h2>
                <TextEditor
                  text={value}
                  onChange={onChange}
                  mode="application/json"
                />
              </Card>
            )}
          </div>
        )}
      </div>
      <Footer>
        {error && <span className="error-message">{error}</span>}
        <Actions>
          {selected && (
            <button disabled={error || !hasChanged} onClick={onSave}>
              Save
            </button>
          )}
          {selected && DeleteKeyButton(keySpace, selected, apiClient, refresh)}
          {keySpace && DeleteKeyspaceButton(apiClient, keySpace, refresh)}
        </Actions>
      </Footer>
    </Styles>
  );
}

function DeleteKeyButton(keySpace, id, apiClient, refresh) {
  return (
    <button
      onClick={() => {
        const confirm = window.confirm(
          `Are you sure you want to delete ${keySpace}/${id}?`
        );
        if (!confirm) return;
        apiClient.get(`/admin/kv/${keySpace}/${id}/delete`).then(refresh);
      }}
    >
      Delete This Object
    </button>
  );
}

function DeleteKeyspaceButton(apiClient, keySpace, refresh) {
  return (
    <button
      className="danger"
      onClick={() => {
        apiClient.get(`/admin/kv/${keySpace}/delete_all`).then(refresh);
      }}
    >
      Delete All Objects in {keySpace}
    </button>
  );
}

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .header-form {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .empty-keyspace {
    margin: 1em;
  }

  .keyspaces {
    border-bottom: 1px solid ${Colors.border};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 8em;
    padding-right: 8em;
    a {
      padding: 8px;
      margin-right: 8px;
    }
    a.selected {
      background-color: ${Colors.altBackgroundDarker};
      :hover {
        text-decoration: none;
      }
    }
  }

  .error-message {
    color: ${Colors.deleteBorder};
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow-y: auto;

    .keys {
      width: 240px;
      display: block;
      overflow-y: auto;

      ul {
        list-style: none;
        width: 100%;
        li {
          font-family: "MonoLisa", "Courier New", Courier, monospace;
          font-size: 0.7em;
          padding: 8px;
        }
        li.selected {
          background-color: ${Colors.altBackgroundDarker};
          border: 1px solid ${Colors.border};
        }
      }
    }

    .selected {
      pre {
        font-size: 0.7em;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
      flex: 1;
      overflow-y: auto;
    }
  }
`;
