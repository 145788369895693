import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import Colors, { Header, Card, Actions, Content } from "../shared-components";

import styled from "styled-components";
import ThinkingDice from "../components/ThinkingDice";
import Image from "../components/Image";
import { useNavigate } from "react-router-dom";
import { characterUrl } from "../components/Main";

const planNames = ["Free", "Basic", "Premium", "Admin"];

export default function Players() {
  const { apiClient } = useContext(AuthContext);
  const [players, setPlayers] = useState(null);
  const [player, setPlayer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!apiClient) return;
    apiClient
      .get(`/admin/players`)
      .then(({ data }) => {
        setPlayers(data);
      })
      .catch((err) => {
        navigate("/");
      });
  }, [apiClient, navigate]);

  if (!players) return <ThinkingDice fullScreen />;
  return (
    <Styles>
      <Header>
        <h1>Players</h1>

        <Actions>
          <input type="search" placeholder="Search" />
        </Actions>
      </Header>
      <Content className="content">
        <div className="players">
          <Card>
            <table className="players-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Plan</th>
                  <th>Last Played</th>
                </tr>
              </thead>
              <tbody>
                {players.map((p) => (
                  <tr
                    key={p.id}
                    onClick={() => setPlayer(p)}
                    className={p.id === player?.id ? "selected" : "clickable"}
                  >
                    <td>{p.id.slice(0, 5) + "..."}</td>
                    <td>{p.name}</td>
                    <td>{p.email}</td>
                    <td>{planNames[p.plan]}</td>
                    <td>{new Date(p.lastSeen).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
        <div className="selection">
          {player && (
            <Card>
              <SelectedPlayer player={player} />
            </Card>
          )}
        </div>
      </Content>
    </Styles>
  );
}

function SelectedPlayer({ player }) {
  const [characters, setCharacters] = useState(null);
  const { apiClient } = useContext(AuthContext);
  useEffect(() => {
    if (!apiClient) return;
    apiClient
      .get(`/players/${player.id}/characters`)
      .then(({ data }) => {
        setCharacters(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [apiClient, player]);

  function setPlan(plan) {
    apiClient
      .post(`/admin/players/${player.id}/plan`, { plan })
      .then(() => {
        console.log("success", plan);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <h2>{player.name}</h2>
      <p>{player.email}</p>
      <p>{new Date(player.lastSeen).toLocaleString()}</p>

      <h3>Current Plan</h3>
      <p>Plan: {planNames[player.plan]}</p>

      <h3>Change Plan</h3>
      <button onClick={() => setPlan(0)}>Free</button>
      <button onClick={() => setPlan(1)}>Basic</button>
      <button onClick={() => setPlan(2)}>Premium</button>

      <h3>Characters</h3>
      <ul>
        {characters &&
          characters.map((c) => (
            <li key={c.id}>
              <a href={characterUrl(c.id)} className="character">
                <Image id={c.imageId || c.dalleImageId} width={40} />
                {c.name}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
}

const Styles = styled.div`
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex-direction: row;
  }
  .players {
    width: 60%;
    table.players-table {
      width: 100%;
      tbody {
        tr {
          cursor: pointer;
        }
        tr.selected {
          background-color: ${Colors.altBackgroundDarker};
          outline: 1px solid ${Colors.border};
          font-weight: bolder;
        }
      }
    }
    overflow-y: auto;
  }
  .selection {
    width: 40%;
    overflow-y: auto;

    .character {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5em;
      img {
        margin-right: 1em;
      }
      font-size: 1.2em;
    }
  }
`;
