import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import Colors, { Header, Card, Actions, Content } from "../shared-components";

import styled from "styled-components";
import ThinkingDice from "../components/ThinkingDice";
import { useNavigate } from "react-router-dom";

export default function WaitListAdmin() {
  const { apiClient } = useContext(AuthContext);
  const [players, setPlayers] = useState(null);
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!apiClient) return;
    apiClient
      .get(`/waitlist`)
      .then(({ data }) => {
        setPlayers(data);
      })
      .catch((err) => {
        navigate("/");
      });
  }, [apiClient, navigate]);

  if (!players) return <ThinkingDice fullScreen />;

  function togglePlayer(player) {
    if (selected.includes(player.id)) {
      setSelected(selected.filter((p) => p !== player.id));
    } else {
      setSelected([...selected, player.id]);
    }
  }

  function removePlayers() {
    if (selected.length === 0) return;
    apiClient
      .post(`/waitlist/accept`, { playerIds: selected })
      .then(() => {
        setPlayers(players.filter((p) => !selected.includes(p.id)));
        setSelected([]);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Styles>
      <Header>
        <h1>Wait List</h1>
        <Actions>
          <button onClick={() => setSelected([])}>Clear</button>
          <button onClick={() => setSelected(players.map((p) => p.id))}>
            Select All
          </button>
          <button
            className="primary"
            enabled={selected.length > 0}
            onClick={removePlayers}
          >
            Accept
          </button>
        </Actions>
      </Header>
      <Content className="content">
        <div className="players">
          <Card>
            <table className="players-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Last Played</th>
                </tr>
              </thead>
              <tbody>
                {players.map((p) => {
                  const className = selected.includes(p.id) ? "selected" : "";
                  return (
                    <tr
                      key={p.id}
                      onClick={() => togglePlayer(p)}
                      className={className}
                    >
                      <td>{p.id.slice(0, 5) + "..."}</td>
                      <td>{p.name}</td>
                      <td>{p.email}</td>
                      <td>{new Date(p.lastSeen).toLocaleString()}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      </Content>
    </Styles>
  );
}

const Styles = styled.div`
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex-direction: row;
  }
  .players {
    width: 100%;
    table.players-table {
      width: 100%;
      tbody {
        tr {
          cursor: pointer;
        }
        tr.selected {
          background-color: ${Colors.altBackgroundDarker};
          outline: 1px solid ${Colors.border};
          font-weight: bolder;
        }
      }
    }
    overflow-y: auto;
  }
`;
