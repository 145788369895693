import { useEffect, useState } from "react";
import Markdown from "react-remarkable";

export default function StaticMarkdown({ file }) {
  const [markdown, setMarkdown] = useState(null);
  useEffect(() => {
    fetch(file).then((response) => {
      response.text().then((text) => {
        setMarkdown(text);
      });
    });
  }, [file]);

  if (!markdown) return "";
  // render markdown
  return <Markdown source={markdown} />;
}
