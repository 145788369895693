import styled from "styled-components";

import { Card, Colors, Paragraphs } from "../shared-components";
import OverlayDialog from "./OverlayDialog";
import ScrollingTextOverlay from "./ScrollingTextOverlay";
import CenteredModal from "./CenteredModal";
import Image from "./Image";
import { AudioPlayer } from "./AudioPlayer";

export function AdventureStoryIntro({ adventure, onClose }) {
  const { introduction, coverArtUrl, introductionAudioUrl } = adventure;

  if (!adventure) return "";

  return (
    <CenteredModal onClose={onClose} show={adventure}>
      <AudioPlayer url={introductionAudioUrl} startPlaying />
      <ScrollingTextOverlay
        imageUrl={coverArtUrl}
        text={introduction}
        size={600}
      />
    </CenteredModal>
  );
}

export default function AdventureDialog({ adventure, onClose, children }) {
  if (!adventure) return "";
  return (
    <OverlayDialog onClose={onClose} show={adventure} width="70%">
      <Adventure {...adventure} children={children} />
    </OverlayDialog>
  );
}

function Adventure({ imageId, name, introduction, storySummary, children }) {
  return (
    <Styles>
      <Card className="adventure">
        <h1>{name}</h1>
        <Image className="framed" id={imageId} />
        <h2>Introduction</h2>
        <Paragraphs text={introduction} />
        <h2>Story So Far</h2>
        <Paragraphs text={storySummary} />
        {children}
      </Card>
    </Styles>
  );
}

const Styles = styled.div`
  .adventure {
    h1 {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid ${Colors.border};
    }
    img {
      width: clamp(300px, 50%, 100%);
      height: auto;
      object-fit: cover;
      float: right;
      margin: 1em;
    }
  }
`;
