import ReactCodeMirror from "@uiw/react-codemirror";
import Colors from "../shared-components";

export default function TextEditor({ text, onChange, mode }) {
  const prefersDarkMode = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const codeMirrorOptions = {
    basicSetup: {
      lineNumbers: true,
      lineWrapping: true,
      foldGutter: true,
    },
    theme: prefersDarkMode ? "dark" : "light",
    style: {
      background: Colors.altBackgroundDarker,
      fontSize: "14px",
    },
    mode,
  };

  return (
    <ReactCodeMirror value={text} onChange={onChange} {...codeMirrorOptions} />
  );
}
