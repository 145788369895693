import styled from "styled-components";
import { useEffect, useState } from "react";
import Colors from "../shared-components";

const STEPS = [
  ["Creating World", 30000, 0, 45],
  ["Creating Adventure", 25000, 45, 85],
  ["Painting Cover Art", 12000, 85, 100],
  ["Ready", 1, 100, 100],
];

export default function CreateCharProgressBar({ status, characterId }) {
  const [statusChangeTime, setStatusChangeTime] = useState(Date.now());
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setStatusChangeTime(Date.now());
  }, [status, characterId]);

  useEffect(() => {
    const step = STEPS.find((step) => step[0] === status);
    if (!step) return;
    const interval = setInterval(() => {
      // eslint-disable-next-line no-unused-vars
      const [_, stepTime, startPercent, endPercent] = step;
      const timeElapsed = Date.now() - statusChangeTime;
      let percentDone =
        (timeElapsed / stepTime) * (endPercent - startPercent) + startPercent;
      if (percentDone > endPercent) percentDone = endPercent;
      setPercent(percentDone);
    }, 200);

    return () => clearInterval(interval);
  }, [status, statusChangeTime]);

  return (
    <Styles>
      <div className="progress" style={{ width: `${percent}%` }}></div>
    </Styles>
  );
}

const Styles = styled.div`
  width: 100%;
  height: 6px;
  margin: 3px 0;
  border-radius: 6px;
  border: 1px solid ${Colors.lighterText};
  margin-bottom: 1em;
  .progress {
    height: 100%;
    background-color: ${Colors.lighterText};
  }
`;
