import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { useState, useRef, useEffect } from "react";

export const AudioPlayer = ({ url, startPlaying }) => {
  const audioRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [pulse, setPulse] = useState(false);

  const playPause = () => {
    if (playing) {
      audioRef.current.pause();
      setPlaying(false);
    } else {
      audioRef.current.play();
      setPlaying(true);
    }
  };

  useEffect(() => {
    if (startPlaying && audioRef.current) {
      // this might fail if the user hasn't interacted with the page yet
      const promise = audioRef.current.play();
      promise
        .then(() => {
          setPlaying(true);
        })
        .catch((error) => {
          console.info("Autoplay was prevented.");
        });
    }
  }, [audioRef, startPlaying]);

  if (!url) return "";
  const icon = playing ? faPause : faPlay;
  return (
    <>
      <audio ref={audioRef} src={url} />

      <Styles
        className="clickable"
        onClick={playPause}
        onMouseEnter={() => setPulse(true)}
        onMouseLeave={() => setPulse(false)}
      >
        <FontAwesomeIcon icon={icon} beat={pulse} />
      </Styles>
    </>
  );
};

const Styles = styled.div`
  // show on top of the container at the bottom right corner
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0.5rem;
  padding: 0.5rem 0.8rem;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1;
`;
