import { Content, Header, Subtitle } from "../shared-components";
import StaticMarkdown from "./StaticMarkdown";
import styled from "styled-components";

export default function Help() {
  return (
    <Styles>
      <Header>About Rolecraft</Header>
      <Content className="about">
        <div className="dm-portrait">
          <img
            src="/images/DungeonMaster.jpg"
            alt="Dungeon Master"
            className="framed"
          />
          <Subtitle className="subtitle">
            I'm your AI based Game Master
          </Subtitle>
        </div>
        <StaticMarkdown file="/help.md" />
      </Content>
    </Styles>
  );
}

const Styles = styled.div`
  .about {
    display: block;
    .dm-portrait {
      float: right;
      margin-left: 1em;
      margin-bottom: 1em;

      img {
        width: clamp(200px, 30vw, 500px);
        height: auto;
      }
      .subtitle {
        width: 100%;
        text-align: center;
      }
    }

    a {
      text-decoration: underline;
    }
  }
`;
