import React, { useEffect, useRef, useState, useContext } from "react";

import styled from "styled-components";
import { AuthContext } from "../AuthContext";
import Colors, { Actions, Content, Header } from "../shared-components";
import ThinkingDice from "./ThinkingDice";
import GameSystemCard from "./GameSystemCard";

export default function NewGameSystem() {
  const promptRef = useRef(null);
  const { apiClient, userId } = useContext(AuthContext);
  const [thinking, setThinking] = useState(false);
  const [error, setError] = useState(null);
  const [gameSystems, setGameSystems] = useState([]);

  useEffect(() => {
    promptRef.current.value = "A space opera in a galaxy far far away...";
    promptRef.current.focus();

    if (!apiClient || !userId) return;
    apiClient.get(`/players/${userId}/gamesystems`).then(({ data }) => {
      console.log("Got game systems", data);
      setGameSystems(data);
    });
  }, [apiClient, userId]);

  function handleSubmit(e) {
    e.preventDefault();
    const prompt = promptRef.current.value;
    const body = { prompt };
    setThinking(true);
    apiClient
      .post(`/players/${userId}/gamesystems/new`, body)
      .then(({ data }) => {
        const gameSystem = data;
        openGameSystem(gameSystem);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setThinking(false);
      });
  }

  return (
    <Styles>
      <Header>
        <h1>Create Your Own Game System</h1>
      </Header>
      <Content className="content">
        <form onSubmit={handleSubmit}>
          <p>
            Rolecraft makes it easy for you to create your own game system. To
            create your own game system, you need to be familiar with YAML,
            Handlebars and Markdown.
          </p>
          {error && <p className="error">Error: {error}</p>}
          <label>Describe your game system</label>
          <input
            ref={promptRef}
            type="text"
            placeholder="Describe your game system"
          />
          {!thinking && (
            <Actions>
              <button className="primary" type="submit">
                Create Your New Game System
              </button>
            </Actions>
          )}
          {thinking && <ThinkingDice title="Creating Your New Game System" />}
          {gameSystems?.length > 0 && (
            <>
              <p>Or edit one of the game systems you've already created:</p>
              <ul>
                {gameSystems.map((gameSystem) => (
                  <li key={gameSystem.handle} className="clickable">
                    <GameSystemCard
                      {...gameSystem}
                      onClick={() => openGameSystem(gameSystem)}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </form>
      </Content>
    </Styles>
  );
}

function openGameSystem(gameSystem) {
  window.location.href = `/gamesystems/${gameSystem.handle}/edit`;
}

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .content {
    height: 100%;
  }

  .error {
    color: red;
  }

  form {
    display: flex;
    flex-direction: column;
    width: clamp(300px, 100%, 1000px);
    margin: 0 auto;

    input {
      font-size: 1.5rem;
      padding: 0.5rem;
      margin: 0.5rem 0;
    }
  }

  // wrap list horizontally with
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;

    li {
      background-color: ${Colors.altBackgroundDarker};
      border-right: 1px solid ${Colors.border};
      border-bottom: 1px solid ${Colors.border};
      width: clamp(200px, 100%, 300px);
      height: 4em;
      margin: 0.5rem;
    }
  }
`;
