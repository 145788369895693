import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../AuthContext";

export default function Image({
  id,
  width,
  height,
  defaultImage,
  className,
  framed,
}) {
  height = height || width;
  const [imageUrl, setImageUrl] = useState("");
  if (framed) className = `${className} framed`;

  const { apiClient } = useContext(AuthContext);
  useEffect(() => {
    if (!apiClient || !id) return;
    apiClient.get(`/images/${id}`).then(({ data }) => {
      setImageUrl(data.url);
    });
  }, [apiClient, id]);

  function regenerateImage(e) {
    if (!e.shiftKey) return;
    e.preventDefault();

    const confim = window.confirm("Regenerate this image?");
    if (!confim) return;

    setImageUrl("/images/generating-image.jpg");
    apiClient.post(`/images/${id}/regenerate`).then(({ data }) => {
      setImageUrl(data.url);
    });
  }

  defaultImage = defaultImage || "/images/generating-image.jpg";
  if (!id)
    return (
      <img
        src={defaultImage}
        alt="Generating..."
        width={width}
        height={height}
        className={className}
      />
    );
  if (!imageUrl) return "";
  return (
    <img
      onClick={regenerateImage}
      src={imageUrl}
      alt="Leonardo"
      width={width}
      height={height}
      className={className}
    />
  );
}
