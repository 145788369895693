import styled from "styled-components";
import { useEffect } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { Body, Header } from "../shared-components";
import { supabase } from "../AuthContext";

export default function Login() {
  // no login session, show the login page
  const providers = ["discord", "google"];

  // get the redirect url param
  const urlParams = new URLSearchParams(window.location.search);
  const redirect = urlParams.get("redirect");

  return (
    <Body>
      <Styles>
        <Header className="transparent">
          <h1>Rolecraft</h1>
          <h2>AI Based Roleplaying Game Platform</h2>
        </Header>
        <div className="auth-container">
          <Auth
            providers={providers}
            onlyThirdPartyProviders={true}
            supabaseClient={supabase}
            queryParams={{ redirect }}
            appearance={{
              extend: true,
              className: {
                container: "auth",
                button: "auth-button",
              },
            }}
          />
        </div>
      </Styles>
    </Body>
  );
}

export function Logout() {
  useEffect(() => {
    supabase.auth.signOut().then(() => {
      window.location.href = "/";
    });
  }, []);
  return "";
}

const Styles = styled.div`
  background-image: url("/images/cover-art-small.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;

  .transparent {
    background-color: transparent;
    border-bottom: none;
    color: white;
    text-shadow: 0 0 10px black;
  }

  .auth-container {
    position: fixed;
    left: 50vw;
    top: 80vh;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    .auth {
      flex-direction: row-reverse;
      width: 100%;
    }

    .auth-button {
      // translucent white
      background-color: rgba(255, 255, 255, 0.6);
      color: black;
      font-family: "Roboto", sans-serif;
    }
    .auth-button:hover {
      // opaque white
      background-color: rgba(255, 255, 255, 1);
    }
  }

  .center {
    text-align: center;
    width: 100%;
  }
`;
