import { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";

import {
  Body,
  Colors,
  Actions,
  Content,
  Header,
} from "../shared-components.js";
import ThinkingDice from "../components/ThinkingDice.js";
import ChatGptLog from "./ChatGptLog.js";
import { AuthContext } from "../AuthContext.js";

const ACTIONS = [
  "next",
  "summarizeMessage",
  "updatePartyMembers",
  "summarizeAdventure",
  "createAdventure",
  "updateCharacterSheets",
  "addPicture",
  "updateState",
  "updateXp",
];

export default function ChatGptLogs() {
  const [doRefresh, setDoRefresh] = useState(false);
  const [items, setItems] = useState(null);
  const [index, setIndex] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [search, setSearch] = useState(null);
  const [action, setAction] = useState(null);
  const [feedbackOnly, setFeedbackOnly] = useState(false);

  const inputRef = useRef(null);

  const { apiClient } = useContext(AuthContext);
  const updateSearch = (e) => {
    const searchTerm = inputRef.current.value;
    e.preventDefault();

    setSearch(searchTerm);
  };

  useEffect(() => {
    if (!apiClient) return;
    let path = `/admin/chatGptLogs?feedbackOnly=${feedbackOnly}`;
    if (search && search.length > 0) {
      path = path + `&searchTerm=${search}`;
    }
    if (action && action !== "All") {
      path = path + `&action=${action}`;
    }

    apiClient.get(path).then(({ data }) => {
      setItems(data || []);
      setIndex(0);
      setDoRefresh(false);
    });

    window.title = "Chat GPT Logs";
    // send focus to the input field
    inputRef.current && inputRef.current.focus();
  }, [apiClient, doRefresh, search, action, feedbackOnly]);

  if (!items) return <ThinkingDice fullScreen />;
  if (items.length === 0) return <Body />;

  if (showTable) {
    return (
      <Styles>
        <Header className="header">
          <h1>Chat GPT Logs</h1>
          <Actions>
            <form onSubmit={updateSearch}>
              <select
                type="select"
                value={null}
                onChange={(e) => setAction(e.target.value)}
              >
                <option value={null}>All</option>
                {ACTIONS.map((action) => (
                  <option key={action} value={action}>
                    {action}
                  </option>
                ))}
              </select>
              <input
                type="checkbox"
                onChange={() => setFeedbackOnly(!feedbackOnly)}
                checked={feedbackOnly}
              />
              <label>Feedback Only</label>
              <input ref={inputRef} type="search" placeholder="Search..." />
            </form>
          </Actions>
        </Header>
        <Content className="content">
          <table>
            <thead>
              <tr>
                <th>Action</th>
                <th>User Prompt</th>
                <th>Completion Text</th>
                <th>Feedback</th>
                <th>Tokens</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, i) => (
                <tr
                  key={i}
                  onClick={() => {
                    setShowTable(false);
                    setIndex(i);
                  }}
                >
                  <td>{item.action || ""}</td>
                  <td>{item.userPrompt.substring(0, 90)}</td>
                  <td>{item.completionText.substring(0, 180)}</td>
                  <td>{item.feedback?.substring(0, 90)}</td>
                  <td className="right">{item.totalTokens}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Content>
      </Styles>
    );
  }

  return (
    <ChatGptLog
      id={items[index].id}
      index={index}
      onClose={() => {
        setShowTable(true);
        setIndex(null);
        setDoRefresh(true);
      }}
      onNext={() => index < items.length - 2 && setIndex(index + 1)}
      onPrevious={() => index > 0 && setIndex(index - 1)}
    />
  );
}

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  .header {
    font-size: 0.7em;
  }

  .content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    table {
      background-color: ${Colors.background};
      thead {
        tr {
          background-color: ${Colors.altBackgroundDarker};
          th {
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #ddd;
          background-color: ${Colors.altBackgroundLighter};
        }
        tr:hover {
          background-color: ${Colors.altBackgroundDarker};
        }
      }
      td,
      th {
        padding: 5px;
      }
    }
  }
`;
