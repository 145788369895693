import WithAuth from "./AuthContext.js";
import Main from "./components/Main.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StatusPage from "./components/StatusPage.js";

const SYSTEM_DOWN = false;

function showStatusPage() {
  return SYSTEM_DOWN && window.location.hostname !== "localhost";
}
function App() {
  if (showStatusPage()) {
    return <StatusPage />;
  }
  return (
    <WithAuth>
      <ToastContainer hideProgressBar autoClose={3000} />
      <Main />
    </WithAuth>
  );
}

export default App;
