import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Colors, {
  Body,
  Card,
  Content,
  isMobileScreen,
} from "../shared-components";
import { CharacterSheet } from "./CharacterDialog";
import { AuthContext } from "../AuthContext";
import ThinkingDice from "./ThinkingDice";
import CreateCharProgressBar from "./CreateCharProgressBar";
import { characterUrl } from "./Main";

export default function SignupNow() {
  const characterId = useParams()["id"];
  const [character, setCharacter] = useState(null);
  const [status, setStatus] = useState("Please wait...");
  const navigate = useNavigate();
  const { apiClient } = useContext(AuthContext);

  useEffect(() => {
    if (!apiClient || !characterId) return;
    apiClient
      .get(`/characters/${characterId}`)
      .then(({ data }) => {
        setCharacter(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [apiClient, characterId]);

  // poll until the campaign is in the ready state
  useEffect(() => {
    if (!character?.campaignId || !apiClient) return;
    const interval = setInterval(() => {
      apiClient
        .get(`/campaigns/${character.campaignId}`)
        .then(({ data }) => {
          const { status } = data;
          setStatus(status);

          // if there was an error, try again
          if (status === "Error") {
            apiClient
              .post(`/characters/${character.id}/recreateCampaign`)
              .then(({ data }) => {
                console.log("Recreate Campaign", data);
                setCharacter(data);
              });
          }
          if (status === "Ready") {
            window.location.href = characterUrl(character.id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }, 1000);

    if (status === "Ready" || status === "Error") {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [apiClient, character, status, navigate]);

  if (!character) return "";
  return (
    <Body>
      <Styles>
        <Content className="content">
          <Card>
            <ThinkingDice fullWidth title={`${status}...`} />
            <CreateCharProgressBar status={status} characterId={character.id} />
            <SuperTitle>Your Character</SuperTitle>
            <CharacterSheet className="char-sheet" character={character} />
          </Card>
        </Content>
      </Styles>
    </Body>
  );
}

const SuperTitle = styled.div`
  font-size: 1.5em;
  width: 100%;
  text-align: center;
  font-style: italic;
  color: ${Colors.lighterText};
`;

const Styles = styled.div`
  background-image: url("/images/map.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 2em;

  ${!isMobileScreen() &&
  `

  .content {
    background-color: ${Colors.background};
    flex: 1;
    display: flex;
    flex-direction: row;
    width: clamp(300px, 500px, 600px);
    margin: 0 auto;
    overflow-y: auto;
    padding: 0;

  }`}

  ${isMobileScreen() &&
  `
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      font-size: 0.7em;
    }
  }
  .content {
    background-color: ${Colors.background};
    flex: 1;
    display: flex;
    flex-direction: column;
  }`}

  .game-master {
    margin-left: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: clamp(200px, 80%, 500px);
      height: auto;
    }
    .subtitle {
      width: 100%;
      text-align: center;
    }
  }

  .auth {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
  }
`;
