import { useState, useEffect, useContext, useRef } from "react";

import styled from "styled-components";
import { AuthContext } from "../AuthContext";
import Colors, {
  Actions,
  Content,
  Header,
  Tooltip,
} from "../shared-components";
import Image from "./Image";
import CharacterDialog from "./CharacterDialog";
import AdventureDialog from "./AdventureDialog";
import { characterUrl } from "./Main";
import ThinkingDice from "./ThinkingDice";

export default function LookingForGroup() {
  const [campaigns, setCampaigns] = useState(null);
  const searchRef = useRef(null);
  const { apiClient } = useContext(AuthContext);

  const updateCampaigns = (e) => {
    if (e) e.preventDefault();
    const searchTerm = searchRef.current?.value || "";
    apiClient
      .get("/campaigns/lfg?search=" + searchTerm)
      .then(({ data }) => {
        setCampaigns(data);

        searchRef.current?.focus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!apiClient) return;
    updateCampaigns();
    // eslint-disable-next-line
  }, [apiClient]);

  const searchTerm = searchRef.current?.value || "";
  return (
    <Styles>
      <Header>
        <h1>Find a Campaign to Join</h1>
        <Actions className="search-bar">
          <form onSubmit={updateCampaigns}>
            <input
              type="search"
              placeholder="Search"
              ref={searchRef}
              onSubmit={updateCampaigns}
              onInput={(e) => {
                if (e.target.value === "") updateCampaigns();
              }}
            />
          </form>
        </Actions>
      </Header>
      <Content className="content">
        {!campaigns && <ThinkingDice title="Searching for campaigns" />}
        {campaigns?.length === 0 && <NoCampaigns searchTerm={searchTerm} />}
        <ul>
          {campaigns?.map((campaign) => (
            <CampaignRow key={campaign.id} campaign={campaign} />
          ))}
        </ul>
      </Content>
    </Styles>
  );
}

function NoCampaigns({ searchTerm }) {
  return (
    <div className="no-campaigns">
      <h2>Oh No!</h2>
      {searchTerm === "" && (
        <p>There are no public campaigns running at the moment.</p>
      )}
      {searchTerm !== "" && (
        <p>
          There are no public campaigns running at the moment that match{" "}
          <strong>"{searchTerm}"</strong>.
        </p>
      )}
      <p>
        You can <a href="/characters/new">create your own campaign</a> and
        invite your friends to join you.
      </p>
      <Actions>
        <a href="/characters/new" className="primary button">
          Create a new Character and Campaign
        </a>
      </Actions>
    </div>
  );
}

function CampaignRow({ campaign }) {
  const [showDialog, setShowDialog] = useState(false);

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <li className="campaign-row clickable">
      <Image id={campaign.adventure.imageId} width={100} height={100} />
      {showDialog && (
        <AdventureDialog adventure={campaign.adventure} onClose={onClose}>
          <AdventureDialogExtras campaign={campaign} onClose={onClose} />
        </AdventureDialog>
      )}
      <div
        className="summary"
        onClick={() => {
          setShowDialog(true);
        }}
      >
        <h2>
          {campaign.adventure.name}
          <Dot />
          <span>{campaign.gameSystem.name}</span>
          <Dot />
          <span>Level {campaign.level}</span>
          <Dot />
          <span>Owner: {campaign.leadCharacter.player.name}</span>
        </h2>

        <p>{campaign.adventure.introduction}</p>
      </div>
      <div className="characters">
        <MiniCharacter {...campaign.leadCharacter} />
        {campaign.otherCharacters.map((character) => (
          <MiniCharacter key={character.id} {...character} />
        ))}
      </div>
    </li>
  );
}

function Dot() {
  return <span className="dot">•</span>;
}

function MiniCharacter({
  imageId,
  name,
  description,
  isLeadCharacter,
  id,
  playerId,
}) {
  const [showSheet, setShowSheet] = useState(false);
  const className = isLeadCharacter ? "lead-character" : "";

  return (
    <div>
      {showSheet && (
        <CharacterDialog
          character={{
            imageId,
            name,
            description,
            id,
            isLeadCharacter,
            playerId,
          }}
          hideActions
          onClose={() => {
            setShowSheet(false);
          }}
        />
      )}
      <div
        className={`character ${className} clickable`}
        onClick={() => setShowSheet(true)}
      >
        <Tooltip text={name} placement="bottom">
          <Image id={imageId} />
        </Tooltip>
      </div>
    </div>
  );
}

function AdventureDialogExtras({ campaign, onClose }) {
  const [url, setUrl] = useState(null);
  const [relationship, setRelationship] = useState("none");
  const { userId } = useContext(AuthContext);

  useEffect(() => {
    const pc = campaign.otherCharacters.find((c) => c.playerId === userId);
    if (pc) {
      setRelationship("member");
      setUrl(characterUrl(pc.id));
    } else if (campaign.leadCharacter.playerId === userId) {
      setRelationship("owner");
      setUrl(characterUrl(campaign.leadCharacter.id));
    } else {
      setRelationship("none");
      setUrl(characterUrl(campaign.leadCharacter.id));
    }
  }, [campaign, userId]);

  function onJoin() {
    window.location.href = url;
  }

  const buttonLabel = relationship === "none" ? "Join" : "Continue";

  return (
    <>
      <h2>Characters</h2>
      <div className="adventure-characters">
        <ul>
          <AdventureCharacter {...campaign.leadCharacter} />
          {campaign.otherCharacters?.map((character) => (
            <AdventureCharacter key={character.id} {...character} />
          ))}
        </ul>
      </div>
      <Actions>
        <button onClick={onClose}>Cancel</button>
        <button className="primary" onClick={onJoin}>
          {buttonLabel}
        </button>
      </Actions>
    </>
  );
}
function AdventureCharacter({
  imageId,
  name,
  summary,
  player,
  isNPC,
  isLeadCharacter,
}) {
  const className = isLeadCharacter ? "lead-character" : "";

  return (
    <li className={`character ${className}`}>
      <Image id={imageId} />
      <div className="summary">
        <h2>{name}</h2>
        <div>
          <span>{summary}</span>
          <Dot />
          {isNPC && <span>(NPC)</span>}
          {!isNPC && <span>Player: {player.name}</span>}
        </div>
      </div>
    </li>
  );
}

const Styles = styled.div`
  .dot {
    margin: 0 0.5em;
  }
  .search-bar {
    input {
      width: 15em;
      font-size: 0.9em;
      padding: 0.5em;
    }
  }

  display: flex;
  flex-direction: column;
  height: 100vh;

  .content {
    background-color: ${Colors.altBackgroundDarker};
    flex-grow: 1;
    overflow-y: auto;
    .no-campaigns {
      max-width: 30em;
      margin: auto;

      p {
        margin: 0.5em 0;
      }
    }

    ul {
      border-top: 1px dotted ${Colors.border};
    }

    li.campaign-row {
      background-color: ${Colors.background};
      border: 1px dotted ${Colors.border};
      border-top: none;

      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 1em;

      img {
        border: 2px groove ${Colors.border};
      }

      .summary {
        margin-left: 0.5em;
        margin-right: 0.5em;
        padding: 0.5em;
        h2 {
          margin: 0;
          padding: 0;

          span {
            font-size: 0.8em;
            color: ${Colors.lighterText};
            font-style: italic;
          }
        }

        // limit to 2 lines, then ellipsis
        p {
          margin-top: 0.5em;
          margin-left: 0;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: ${Colors.lighterText};
        }
      }
      // make the whole row clickable
      &:hover {
        .summary p {
          text-decoration: underline;
        }
      }
      .characters {
        min-width: 200px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    // alternate rows have a different background color
    li:nth-child(even) {
      background-color: ${Colors.altBackgroundLighter};
    }

    .character {
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }

      &.lead-character {
        img {
          border: 3px solid goldenrod;
        }
      }
    }
    .adventure-characters {
      ul {
        li.character {
          display: flex;
          align-items: center;
          flex-direction: row;
          img {
            margin: 0.5em;
            width: 48px;
            height: 48px;
          }
          .summary {
            display: flex;
            flex-direction: column;
            margin: 0.5em;
            h2 {
              margin: 0;
              padding: 0;
            }
            p {
              margin: 0;
              padding: 0;
              font-size: 0.8em;
              color: ${Colors.lighterText};
            }
          }
        }
      }
    }
  }
`;
