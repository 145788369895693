import { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import { Actions, Card, Colors, Subtitle } from "../shared-components";
import Image from "./Image";
import { AuthContext } from "../AuthContext";
import OverlayDialog from "./OverlayDialog";
import { useNavigate } from "react-router-dom";

export default function CharacterDialog({ character, onClose, hideActions }) {
  const { apiClient, userId } = useContext(AuthContext);
  const navigate = useNavigate();
  if (!character) return "";

  const isOwner = character.playerId === userId && !character.isNpc;
  const isMainCharacter = character.isLeadCharacter;
  const isNpc = !character.playerId;

  function deleteCharacter() {
    // eslint-disable-next-line no-restricted-globals
    const yes = confirm(`Delete ${character.name}? All history will be lost.`);
    if (!yes) return;
    apiClient
      .delete(`/players/${userId}/characters/${character.id}`)
      .finally(() => {
        navigate("/");
      });
  }

  function removeNpc() {
    // eslint-disable-next-line no-restricted-globals
    const yes = confirm(
      `Remove ${character.name} from your party? They will be deleted.`
    );
    if (!yes) return;
    apiClient
      .delete(`/campaigns/${character.campaignId}/npcs/${character.id}`)
      .finally(() => {
        onClose();
      });
  }

  return (
    <OverlayDialog onClose={onClose} show={character} width="22em">
      <Card>
        <CharacterSheet character={character} />
        {!hideActions && (
          <Actions>
            {isOwner && !isMainCharacter && (
              <button className="danger" onClick={deleteCharacter}>
                Delete Your Character
              </button>
            )}
            {isNpc && <button onClick={removeNpc}>Remove from Party</button>}
          </Actions>
        )}
      </Card>
    </OverlayDialog>
  );
}

export function CharacterSheet({ character, template }) {
  const [characterSheetHtml, setCharacterSheetHtml] = useState("");
  const [player, setPlayer] = useState(null);
  const { apiClient, userId } = useContext(AuthContext);
  const characterId = character.id;

  useEffect(() => {
    if (!apiClient) return;

    // in this case, the character sheet is being live edited in
    // the game system builder. We need to post the character sheet
    // and the test character
    if (template) {
      const body = { template, character };

      // debounce
      apiClient.post(`/character_sheet`, body).then(({ data }) => {
        setCharacterSheetHtml(data);
      });
    } else {
      // this is the common case. just load the character sheet.
      // the server treats GET operations with different security
      apiClient
        .get(`/characters/${characterId}/character_sheet`)
        .then(({ data }) => {
          setCharacterSheetHtml(data);
          return character.playerId;
        });
      if (character.playerId) {
        apiClient.get(`/characters/${characterId}/player`).then(({ data }) => {
          setPlayer(data);
        });
      }
    }
  }, [apiClient, character, characterId, template]);

  if (!characterSheetHtml || !character) {
    return "";
  }
  let subTitle = "Player Character";
  if (character.isNpc || !character.playerId) {
    subTitle = "Non-Player Character";
  } else if (character.isLeadCharacter) {
    subTitle = "Lead Character";
  } else if (character.playerId === userId.toString()) {
    subTitle = "Your Character";
  }

  return (
    <Styles>
      <div className="character-sheet">
        <h1 className="centered">{character.name}</h1>
        <Subtitle className="centered">{subTitle}</Subtitle>
        <Subtitle className="centered">
          {player?.name && `Played by ${player.name}`}
          {!player && character.playerId && "Played by Unknown Player"}
        </Subtitle>
        <div className="portrait-container">
          <Image id={character.imageId || character.dalleImageId} />
        </div>
        <div dangerouslySetInnerHTML={{ __html: characterSheetHtml }} />
      </div>
    </Styles>
  );
}

const Styles = styled.div`
  .character-sheet {
    h1 {
      border-bottom: 1px solid ${Colors.border};
    }
    .centered {
      width: 100%;
      text-align: center;
    }

    .portrait-container {
      padding: 1em;

      img {
        width: 100%;
        height: auto;
        border-radius: 50%;
        border: 2px solid ${Colors.border};
      }
    }

    ul {
      list-style: unset;
      padding-left: 1em;
    }
  }
`;
